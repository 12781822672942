import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Navbar() {

    /* const [isActive, setIsActive] = useState(false);
    const toggleDropdown = () => {
        setIsActive(!isActive);
      }; */

    return (
        <div className="main-navigation">
            <nav className="navbar navbar-expand-lg newNabvar">
                <div className="container space-container">
                    <div>
                        <NavLink to="/" className='main_logo'><img src='assets/images/logo.png' alt=' Rastriya e-Pustakalaya' /></NavLink>
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="#fff"><path d="M120-240v-66.67h720V-240H120Zm0-206.67v-66.66h720v66.66H120Zm0-206.66V-720h720v66.67H120Z" /></svg>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">

                        <ul className="mobile-view accordion-body mobile-view-scroll">
                            <li><NavLink to="/home">Aibf</NavLink></li>
                           {/*  <li><NavLink to="/events_schedule">Schedule</NavLink></li> */}
                            <div class="accordion events_menu_mobile" id="accordionExample1">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne1">
                                        <button
                                            class="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne1"
                                            aria-expanded="true"
                                            aria-controls="collapseOne1"
                                        >
                                            Schedule
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne1"
                                        class="accordion-collapse collapse"
                                        aria-labelledby="headingOne1"
                                        data-bs-parent="#accordionExample1"
                                    >
                                        <div class="accordion-body">
                                            <li><NavLink to="/schedule1">30 Nov</NavLink></li>
                                            <li><NavLink to="/schedule2">1 Dec</NavLink></li>
                                            <li><NavLink to="/schedule3">2 Dec</NavLink></li>
                                            <li><NavLink to="/schedule4">3 Dec</NavLink></li>
                                            <li><NavLink to="/schedule5">4 Dec</NavLink></li>
                                            <li><NavLink to="/schedule6">5 Dec</NavLink></li>
                                            <li><NavLink to="/schedule7">6 Dec</NavLink></li>
                                            <li><NavLink to="/schedule8">7 Dec</NavLink></li>
                                            <li><NavLink to="/schedule9">8 Dec</NavLink></li>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <li><NavLink to="/speakers">Speaker</NavLink></li>
                            <div class="accordion events_menu_mobile" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button
                                            class="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            Events
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        class="accordion-collapse collapse"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div class="accordion-body">
                                            <li><NavLink to="/event1">Pragya Shivir</NavLink></li>
                                            <li><NavLink to="/event2">Childern Film Festival</NavLink></li>
                                            <li><NavLink to="/event3">Shabd Sansar</NavLink></li>
                                            <li><NavLink to="/event4">The International Literary Stage</NavLink></li>
                                            <li><NavLink to="/event5">Cultural Stage</NavLink></li>
                                            <li><NavLink to="/event6">Culinary Literature Stage</NavLink></li>
                                            <li><NavLink to="/event7">Gyaan Ganga</NavLink></li>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <li><NavLink to="/blog">Blog & News</NavLink></li>
                            <li><NavLink to="/publisher">Publisher</NavLink></li>
                            <li><NavLink to="/gallery">Gallery</NavLink></li>
                            <li><NavLink to="/contact">Contact Us</NavLink></li>
                            {/* <NavLink to="https://docs.google.com/forms/d/e/1FAIpQLScm2fQ3k7MJPJKxwqzVRo8GMp2Yfv8QlQ0-82I7oCkdkV2QKQ/viewform" target='_blank' className={({ isActive }) => `nav-link btn-register ${isActive ? 'active' : ''}`} >
                                Register now
                            </NavLink> */}
                        </ul>

                        <ul className="navbar-nav ml-auto mb-2 mb-lg-0 desktop-view">

                            <li className="nav-item catagories desktop-view">
                                <NavLink className={({ isActive }) => `nav-link btn-home ${isActive ? 'active' : ''}`} to="/home">
                                    AIBF
                                </NavLink>

                            </li>
                            <li className="nav-item dropdown desktop-view">
                                <NavLink className={({ isActive }) => `nav-link btn-shedule dropdown-arrow  ${isActive ? 'active' : ''}`} to="/events_schedule">
                                    Schedule
                                </NavLink>
                                <ul className="dropdown-position-list">
                                    <li><NavLink to="/schedule1">30 Nov</NavLink></li>
                                    <li><NavLink to="/schedule2">1 Dec</NavLink></li>
                                    <li><NavLink to="/schedule3">2 Dec</NavLink></li>
                                    <li><NavLink to="/schedule4">3 Dec</NavLink></li>
                                    <li><NavLink to="/schedule5">4 Dec</NavLink></li>
                                    <li><NavLink to="/schedule6">5 Dec</NavLink></li>
                                    <li><NavLink to="/schedule7">6 Dec</NavLink></li>
                                    <li><NavLink to="/schedule8">7 Dec</NavLink></li>
                                    <li><NavLink to="/schedule9">8 Dec</NavLink></li>
                                </ul>

                            </li>

                            <li className="nav-item desktop-view">
                                <NavLink to="/speakers" className={({ isActive }) => `nav-link btn-speaker ${isActive ? 'active' : ''}`} >
                                    Speaker
                                </NavLink>
                            </li>

                            <li className="nav-item dropdown desktop-view">
                                <button  className="nav-link dropdown-arrow events_drp">
                                    Events
                                </button>
                                <ul className="dropdown-position-list">
                                    <li><NavLink to="/event1">Pragya Shivir</NavLink></li>
                                    <li><NavLink to="/event2">Childern Film Festival</NavLink></li>
                                    <li><NavLink to="/event3">Shabd Sansar</NavLink></li>
                                    <li><NavLink to="/event4">The International Literary Stage</NavLink></li>
                                    <li><NavLink to="/event5">Cultural Stage</NavLink></li>
                                    <li><NavLink to="/event6">Culinary Literature Stage</NavLink></li>
                                    <li><NavLink to="/event7">Gyaan Ganga</NavLink></li>

                                </ul>

                            </li>

                            <li className="nav-item dropdown desktop-view">
                                <NavLink className={({ isActive }) => `nav-link btn-media  ${isActive ? 'active' : ''}`} to="/blog">
                                    Blog & News
                                </NavLink>

                            </li>
                            <li className="nav-item catagories desktop-view">
                                <NavLink className={({ isActive }) => `nav-link btn-home ${isActive ? 'active' : ''}`} to="/publisher">
                                    Publisher
                                </NavLink>

                            </li>
                            <li className="nav-item catagories desktop-view">
                                <NavLink className={({ isActive }) => `nav-link btn-home ${isActive ? 'active' : ''}`} to="/gallery">
                                    Gallery
                                </NavLink>

                            </li>
                            <li className="nav-item desktop-view">
                                <NavLink to="/contact" className={({ isActive }) => `nav-link btn-contact ${isActive ? 'active' : ''}`} >
                                    Contact us
                                </NavLink>
                            </li>


                        </ul>
                        <div className="d-none">
                            <ul className='desktop_register'>
                                <li className="nav-item desktop-view">
                                    <NavLink to="https://docs.google.com/forms/d/e/1FAIpQLScm2fQ3k7MJPJKxwqzVRo8GMp2Yfv8QlQ0-82I7oCkdkV2QKQ/viewform" target='_blank' className={({ isActive }) => `nav-link btn-register pulse ${isActive ? 'active' : ''}`} >
                                        Register now
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            

        </div>
    )
}
