import React, { useEffect } from "react";
import InternalBanner from './InternalBanner';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import { Fancybox } from "@fancyapps/ui";



export default function Gallery() {

    useEffect(() => {
        Fancybox.bind("[data-fancybox]", {
            infinite: true, // Enable infinite looping
        });
        return () => Fancybox.destroy(); // Clean up on unmount
    }, []);

    const images = [
        "photo_1.jpg",
        "photo_2.jpg",
        "photo_3.jpeg",
        "photo_4.jpeg",
        "photo_5.jpeg",
        "photo_6.jpeg",
        "photo_7.jpeg",
        "photo_8.jpeg",
    ];



    return (
        <>
            <InternalBanner />

            <section className="gallery ex_bg pb-5">
                <div className="container">
                    <div className="row">
                        <h2 className="section-heading mt-5">Highlights from the AIBF 2024</h2>
                    </div>

                    <div className="row row-cols-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                        {images.map((photo, index) => (
                            <div className="thumb tab" key={index}>
                                <a
                                    href={`assets/images/gallery/${photo}`}
                                    data-fancybox="gallery"
                                    data-caption={`Photo ${index + 1}`}
                                >
                                    <img
                                        src={`assets/images/gallery/${photo}`}
                                        className="w-100"
                                        alt={`Gallery Thumbnail ${index + 1}`}
                                    />
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </section>


         
            <section className='youtube_live py-5'>
                <div className='container'>
                    <div className='row'>
                        {/* <div className='col-md-12'>
                            <h2 className='section-heading mt-5'>Youtube Live</h2>
                        </div> */}
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-md-8'>
                            <iframe width="100%" height="405" src="https://www.youtube.com/embed/IcNUV0s5024?autoplay=1&mute=1" title="AHMEDABAD INTERNATIONAL BOOK FESTIVAL 2024" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}
