import React from 'react'
import InternalBanner from './InternalBanner';

export default function Privacy() {
    return (
        <>
            <InternalBanner title="Privacy Policy" />
            <section id="nutrition" className="about-company privacy-company">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="about-content">

                                <p>The National Book Trust India (NBT-India), under the Ministry of Education, Department of Higher Education, Ministry of Education, Government of India, has created the Rashtriya e-Pustakalaya (National Digital Library) for children and adults, which is available as a free-to-use application. This service is provided by the National Book Trust India, at no charge and is designed for use as it is.</p>
                                <p>The purpose of this page is to inform visitors about the application policies regarding the gathering, use, and disclosure of Personal Information if anyone opt to utilize this application’s service.</p>
                                <p>By opting to use the application’s service, you consent to the gathering and use of information as outlined to this policy. The personal information collected by this application is used for providing and enhancing the service and capability. This application will not use or share your information with anyone except as outlined in this Privacy Policy.</p>
                                <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible on the Rashtriya e-Pustakalaya (National Digital Library) unless otherwise defined in this Privacy Policy.</p>
                                <h3>Information Gathering and Use</h3>
                                <p>For a better experience, while using this application's services after downloading the registration is mandatory. This application may require you to provide us with certain personal information, including but not limited to:</p>
                                <p className='mb-0'>Name</p>
                                <p className='mb-0'>Phone number</p>
                                <p className='mb-0'>Email</p>
                                <p className='mb-0'>Gender</p>
                                <p className='mb-0'>Designation</p>
                                <p className='mb-0'>State</p>
                                <p className='mb-0'>District</p>
                                <p className='mb-0'>School</p>
                                <p className='mb-0'>School address</p>
                                <p>Pin code</p>
                                <p>The information asked by the application will be retained on user's device.</p>
                                <p>The user can stop the disclosure of their information by deregistering, deleting their account or uninstalling this application by using uninstalling method available on the devices on which the application has been installed.</p>
                                <p>Third-party services have been used by the application may collect the information that identifies you.</p>
                                <p>Below are the Links to the privacy policies of third-party service providers used by the application</p>
                                <a className='d-block' href="https://www.google.com/policies/privacy/">Google Play Services</a>
                                <a className='d-block' href="https://firebase.google.com/policies/analytics">Google Analytics for Firebase</a>
                                <a className='d-block' href="https://firebase.google.com/support/privacy/">Firebase Crashlytics</a>
                                <h3>Log Data</h3>
                                <p>This is to inform you that whenever you use Rashtriya e-Pustakalaya (National Digital Library) services, in case of an error in the app, the collected data and information (through third-party products) on your phone is called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing my Service, the time and date of your use of the Service, and other statistics.</p>
                                <h3>Cookies</h3>
                                <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.</p>
                                <p>This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</p>
                                <h3>Service Providers</h3>
                                <p>This app may employ third-party companies and individuals due to the following reasons:</p>
                                <p>To facilitate our Service;</p>
                                <p>To provide the Service on our behalf;</p>
                                <p>To perform Service-related services; or</p>
                                <p>To assist us in analyzing how our Service is used.</p>
                                <p>It is to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on the behalf of Rashtriya e-Pustakalaya (National Digital Library). However, they are obligated not to disclose or use the information for any other purpose.</p>
                                <h3>Disclosure of information of users in certain situation:-</h3>
                                <p>The information collected from the users may be disclosed, if there is any reason to believe that such disclosure is required in order to respond to the legal process, for investigation or for the protection of rights of users or any other legal remedy or purposes.</p>
                                <h3>Security</h3>
                                <p>Your trust is being valued for providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and it cannot be guaranteed its absolute security.</p>
                                <h3>Links to Other Sites</h3>
                                <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by this application. Therefore, it is strongly advised that you review the Privacy Policy of these websites. This application has no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
                                <h3>Children’s Privacy</h3>
                                <p>These Services do not address anyone under the age of 13. Rashtriya e-Pustakalaya (National Digital Library) do not knowingly collect personally identifiable information from children under the age of 13 years. If it comes to our attention that a child under 13 has provided their personal information, we will promptly delete the data from our servers. If you are a parent or guardian and you believe that your child has provided us with personal information, please contact us so that we can take appropriate action.</p>
                                <h3>Dispute Resolution:-</h3>
                                <p>All and any dispute arising out of or in connection with this privacy policy, what-so-ever arising out of usage of this application shall be referred to a Sole Arbitrator, who will be the Secretary to the Ministry of Education, Government of India.</p>
                                <h3>Governing Law:-</h3>
                                <p>The terms and conditions of this privacy policy shall be governed by the and construed in accordance with the applicable Indian laws.</p>
                                <h3>Changes to This Privacy Policy</h3>
                                <p>NBT-India reserves the right to make amendments to this policy and the users are advised to review this page periodically for any changes. The users shall be notified of any changes by posting the new Privacy Policy on this page.</p>
                                <p>This policy is effective as of 01-04-2024</p>
                                <h3>Consent</h3>
                                <p>By installing and registering on this Application, you are agreeing to our terms and conditions to process your information as outlined in this Privacy Policy now and as amended by us from periodically.</p>
                                <h3>Contact Us</h3>
                                <p>If you have any questions or suggestions about my Privacy Policy, please contact us at helpdesk.ndledugov@gmail.com</p>


                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
