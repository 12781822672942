import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import Schedule from './components/Schedule';
import Contact from './components/Contact';
import ScrollToTop from './components/ScrollToTop';
import Privacy from './components/Privacy';
import TermCondition from './components/TermCondition';
import ScrollToSection from './components/ScrollToSection';
import NewHeader from './components/NewHeader';
import Footer from './components/Footer';
import Media from './components/Media';
import Gujrati from './components/Gujrati';
import ChildernCorner from './components/ChildernCorner';
import EventSchedule from './components/EventSchedule';
import DetailSpeakers from './components/DetailSpeakers';
import BlogNews from './components/BlogNews';
import Gallery from './components/Gallery';
import Publisher from './components/Publisher';
import Schedule1 from './components/Schedule1';
import Schedule2 from './components/Schedule2';
import Schedule3 from './components/Schedule3';
import Schedule4 from './components/Schedule4';
import Schedule5 from './components/Schedule5';
import Schedule6 from './components/Schedule6';
import Schedule7 from './components/Schedule7';
import Schedule8 from './components/Schedule8';
import Schedule9 from './components/Schedule9';
import PDFLoader from './components/PDFLoader';

import Event1 from './components/Event1';
import Event2 from './components/Event2';
import Event3 from './components/Event3';
import Event4 from './components/Event4';
import Event5 from './components/Event5';
import Event6 from './components/Event6';
import Event7 from './components/Event7';

function App() {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <>
      <Router>

        <ScrollToTop />
        <NewHeader />

        <ScrollToSection />
        <Routes>
          <Route path="/child_corner" element={<ChildernCorner />} />
          <Route path="/speakers" element={<DetailSpeakers />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/events_schedule" element={<EventSchedule />} />
          <Route path="/media" element={<Media />} />
          <Route path="/blog" element={<BlogNews />} />
          <Route path="/publisher" element={<Publisher />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/gujrati" element={<Gujrati />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/term_condition" element={<TermCondition />} />
          <Route path="/schedule1" element={<Schedule1 />} />
          <Route path="/schedule2" element={<Schedule2 />} />
          <Route path="/schedule3" element={<Schedule3 />} />
          <Route path="/schedule4" element={<Schedule4 />} />
          <Route path="/schedule5" element={<Schedule5 />} />
          <Route path="/schedule6" element={<Schedule6 />} />
          <Route path="/schedule7" element={<Schedule7 />} />

          <Route path="/schedule8" element={<Schedule8 />} />
          <Route path="/schedule9" element={<Schedule9 />} />

          <Route path="/event1" element={<Event1 />} />
          <Route path="/event2" element={<Event2 />} />
          <Route path="/event3" element={<Event3 />} />
          <Route path="/event4" element={<Event4 />} />
          <Route path="/event5" element={<Event5 />} />
          <Route path="/event6" element={<Event6 />} />
          <Route path="/event7" element={<Event7 />} />
          <Route path="/pdf" element={<PDFLoader />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}



export default App;
