import React from 'react';

import InternalBanner from './InternalBanner';


export default function Contact() {
    return (
        <>

            <InternalBanner title="Contact Us" />
            <section id="nutrition" className="contact-part ex_bg">
                <div className='row'>
                    <div className='col-md-12'>
                        <h2 class="section-heading">contact us</h2>
                    </div>
                </div>
                <div className="container contact-box">

                    <div className="row justify-content-center">

                        <div className='col-md-12 mb-5'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d918.0018046526892!2d72.57422653882388!3d23.02350716979982!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e854ed07ae52f%3A0x99de52dbb428a4d4!2sRiverfront!5e0!3m2!1sen!2sin!4v1732702684061!5m2!1sen!2sin" title='Map' width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="contact-card h-100">
                                <div className='ic-text'>
                                    <i className="icofont-location-pin"></i>
                                    <h4>Venue</h4>
                                </div>

                                <span className='contact_address'>
                                    Riverfront Event Centre,<br />
                                    Ahmedabad<br />

                                </span>

                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 d-none">
                            <div className="contact-card h-100">
                                <div className='ic-text'>
                                    <i className="icofont-phone"></i>
                                    <h4>phone number</h4>
                                </div>
                                <p>
                                    <span>+91-XXXXXXXXXX</span>

                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="contact-card h-100">
                                <div className='ic-text'>
                                    <i className="icofont-email"></i>
                                    <h4>Support mail</h4>
                                </div>
                                <p>
                                    <span>aibf.nbt@gmail.com</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row d-none">

                        <div className="col-lg-6 col-md-6">
                            <form className="contact-form">
                                <h4 className="mb-3 contact-title">Get in touch</h4>
                                <div className="form-group mb-0">
                                    <div className="did-floating-label-content form-input-group">
                                        <input className="did-floating-input form-control" type="text" placeholder=" " />
                                        <label className="did-floating-label">Your Name *</label>
                                        <i className="fas fa-user"></i>
                                    </div>
                                </div>
                                <div className="form-group mb-0">
                                    <div className="did-floating-label-content form-input-group">
                                        <input className="did-floating-input form-control" type="text" placeholder=" " />
                                        <label className="did-floating-label">Your Email *</label>
                                        <i className="fas fa-envelope"></i>
                                    </div>
                                </div>
                                <div className="form-group mb-0">
                                    <div className="did-floating-label-content form-input-group">
                                        <input className="did-floating-input form-control" type="text" placeholder=" " />
                                        <label className="did-floating-label">Your Subject *</label>
                                        <i className="fas fa-file-alt"></i>
                                    </div>
                                </div>

                                <div className="form-group mb-0">
                                    <div className="did-floating-label-content form-input-group">
                                        <textarea className="did-floating-input form-control" placeholder="" required ></textarea>
                                        <label className="did-floating-label">Message *</label>
                                        <i className="fas fa-comment"></i>
                                    </div>
                                </div>
                                <div className="section-btn-25 mt-0">
                                    <a href="/contact" className="btn btn_orange">
                                        Send
                                    </a>
                                </div>
                            </form>
                        </div>

                    </div>

                </div>
            </section>



        </>
    )
}
