import Slider from 'react-slick';
import Banner from './Banner';
import { NavLink } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IoArrowBack, IoArrowForward } from 'react-icons/io5';


export default function Home() {

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        arrows: true,
        speed: 100,
        slidesToShow: 5,
        slidesToScroll: 1,
        prevArrow: <IoArrowBack className="icofont-arrow-right dandik" />,
        nextArrow: <IoArrowForward className="icofont-arrow-left bamdik" />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                    arrows: true,
                }
            },

        ]
    };

    const settings_child = {
        dots: false,
        infinite: true,
        autoplay: true,
        arrows: true,
        speed: 100,
        slidesToShow: 5,
        slidesToScroll: 1,
        prevArrow: <IoArrowBack className="icofont-arrow-right dandik" />,
        nextArrow: <IoArrowForward className="icofont-arrow-left bamdik" />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                    arrows: true,
                }
            },

        ]
    };


    return (
        <>




            <Banner />

            <section className="card-list activity-list">
                <div className='container'>
                    <div className='row justify-content-center'>
                        <article className="card pink-card">
                            <div className='card_section'>
                                <img src='assets/images/card_img/card_1.png' alt='Pragya Shivir' />
                                <h6>Pragya Shivir</h6>
                                <p>Children’s Pavilion</p>
                                <p className='mt-3 text-start'>(प्रज्ञा शिविर) refers to the highest and purest form of wisdom, intelligence, and understanding. Pragya represents a state ...</p>
                                <NavLink to="/event1" className='detail_btn'>View Details</NavLink>
                            </div>
                        </article>
                        <article className="card purple-card">
                            <div className='card_section'>
                                <img src='assets/images/card_img/card_2.png' alt='Children Film Festival' />
                                <h6>Children Film Festival</h6>
                                <p className='invisible'>xxx</p>
                                <p className='mt-3 text-start'>Ahmedabad International Book Festival presents a unique blend of cinema & literature in collaboration with the Ahmedabad ...</p>
                                <NavLink to="/event2" className='detail_btn'>View Details</NavLink>
                            </div>
                        </article>
                        <article className="card green-card">
                            <div className='card_section'>
                                <img src='assets/images/card_img/card_3.png' alt='Shabd Sansar' />
                                <h6>Shabd Sansar</h6>
                                <p>Author's Corner</p>
                                <p className='mt-3 text-start'>Shabd refers to words as fundamental forms of expression, while Samsara represents the cyclic nature of all life, matter ... </p>
                                <NavLink to="/event3" className='detail_btn'>View Details</NavLink>
                            </div>
                        </article>
                        <article className="card orange-card">
                            <div className='card_section'>
                                <img src='assets/images/card_img/card_4.png' alt='3000 BCE' />
                                <h6>3000 BCE</h6>
                                <p className=''>The International Literary Stage</p>
                                <p className='mt-3 text-start'>Around 3000 BCE, approximately 5,000 years ago, humans in present-day Lothal, located 50 kilometers from the banks of Sabarmati in Ahmedabad...</p>
                                <NavLink to="/event4" className='detail_btn'>View Details</NavLink>
                            </div>
                        </article>
                        <article className="card blue-card">
                            <div className='card_section'>
                                <img src='assets/images/card_img/card_5.png' alt='Cultural Stage' />
                                <h6>Cultural Stage</h6>
                                <p className='invisible'>xxx</p>
                                <p className='mt-3 text-start'>RANGMANCH symbolizes a vibrant confluence of art, culture, and storytelling, bringing alive the essence of creativity through... </p>
                                <NavLink to="/event5" className='detail_btn'>View Details</NavLink>
                            </div>
                        </article>
                        <article className="card sky-blue-card">
                            <div className='card_section'>
                                <img src='assets/images/card_img/card_6.png' alt="Culinary Literature Stage" />
                                <h6>Culinary Literature Stage</h6>
                                <p></p>
                                <p className='mt-3 text-start'>Rasoi & Kitaab celebrates rasa, traditions, and stories passed down from one generation to another. At the heart of Kitchen Table conversations...</p>
                                <NavLink to="/event6" className='detail_btn'>View Details</NavLink>
                            </div>
                        </article>
                       
                    </div>

                </div>

            </section>

         


            <section id="publisher" className='book-by-language px-0 mt-0 featured-slider publisher-img speker_new_img'>
                <div className='container space-container'>
                    <div className='col-md-12 inner_section'>
                        <div className='d-flex justify-content-center align-items-center mb-4'>
                            <h2 className='section-heading mb-0 book-language-title'>Speakers</h2>
                        </div>

                        <Slider {...settings} className="brand-slider slider-arrow">
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_1.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_2.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_3.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_4.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_5.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_6.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_7.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_8.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">

                                    <img src="assets/images/speakers/speaker_9.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_10.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_11.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_12.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_13.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_14.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_15.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_16.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">

                                    <img src="assets/images/speakers/speaker_17.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_18.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_19.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_20.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_21.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_22.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_23.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_24.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">

                                    <img src="assets/images/speakers/speaker_25.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_26.png" alt="speakers" />
                                </div>
                            </div>
                            {/* <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_27.png" alt="speakers" />
                                </div>
                            </div> */}
                           {/*  <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_28.png" alt="speakers" />
                                </div>
                            </div> */}
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_29.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_30.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_31.png" alt="speakers" />
                                </div>
                            </div>
                            <div className="brand-wrap">
                                <div className="brand-media">
                                    <img src="assets/images/speakers/speaker_32.png" alt="speakers" />
                                </div>
                            </div>

                        </Slider>


                    </div>
                </div>


            </section>

            <section id="publisher" className='book-by-language px-0 mt-0 featured-slider publisher-img child_corner'>
                <div className='container space-container'>
                    <div className='col-md-12 pt-5'>
                        <div className='d-flex justify-content-center align-items-center mb-4'>
                            <h2 className='section-heading mb-0 book-language-title'>children's corner</h2>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>

                            <Slider {...settings_child} className="brand-slider slider-arrow pb-4">

                                <div className="brand-wrap">
                                    <div className="brand-media">
                                        <img src="assets/images/childrens_corner/Lata_Hirani.png" alt="Performer" />
                                        <h6>Lata Hirani</h6>
                                        <p>Poet, Writer <br />
                                            Radio-TV Artist</p>
                                    </div>
                                </div>
                                <div className="brand-wrap">
                                    <div className="brand-media">
                                        <img src="assets/images/childrens_corner/Shivani_Kanodia.png" alt="Performer" />
                                        <h6>Shivani Kanodia</h6>
                                        <p>Professional Storyteller</p>
                                    </div>
                                </div>
                                <div className="brand-wrap">
                                    <div className="brand-media">
                                        <img src="assets/images/childrens_corner/Muskan_Sharma.png" alt="Performer" />
                                        <h6>Muskan Sharma</h6>
                                        <p>Theatre Artist Facilitator <br />
                                            Co-Founder, Rebine</p>
                                    </div>
                                </div>
                                <div className="brand-wrap">
                                    <div className="brand-media">
                                        <img src="assets/images/childrens_corner/Farman.png" alt="Performer" />
                                        <h6>Farman</h6>
                                        <p>Theatre Artist-Facilitator<br />
                                            Singer, Lyricist
                                        </p>
                                    </div>
                                </div>
                                <div className="brand-wrap">
                                    <div className="brand-media">
                                        <img src="assets/images/childrens_corner/Raksha_Dave.png" alt="Performer" />
                                        <h6>Raksha Dave</h6>
                                        <p>Renowned Poet, Critic<br />
                                            Children's Writer</p>
                                    </div>
                                </div>
                                <div className="brand-wrap">
                                    <div className="brand-media">
                                        <img src="assets/images/childrens_corner/Grishma_Solanki.png" alt="Performer" />
                                        <h6>Grishma Solanki</h6>
                                        <p>Subject Matter
                                            <br /> Expert- Mathematics</p>
                                    </div>
                                </div>
                                <div className="brand-wrap">
                                    <div className="brand-media">
                                        <img src="assets/images/childrens_corner/Chhavi_Gupta.png" alt="Performer" />
                                        <h6>Chhavi Gupta</h6>
                                        <p>Linguist Sociologist</p>
                                    </div>
                                </div>
                                <div className="brand-wrap">
                                    <div className="brand-media">
                                        <img src="assets/images/childrens_corner/Jignesh_Chavda.png" alt="Performer" />
                                        <h6>Jignesh Chavda</h6>
                                        <p>Animator
                                            Illustrator<br />
                                            Motion Designer</p>
                                    </div>
                                </div>
                                <div className="brand-wrap">
                                    <div className="brand-media">

                                        <img src="assets/images/childrens_corner/Seema_Wahi_Mukherjee.png" alt="Performer" />
                                        <h6>Seema Wahi Mukherjee</h6>
                                        <p>Storytelling Coach</p>
                                    </div>
                                </div>
                               {/*  <div className="brand-wrap">
                                    <div className="brand-media">
                                        <img src="assets/images/childrens_corner/Childrens_Research_University.png" alt="Performer" />
                                        <h6>Children's Research University</h6>
                                        <p>State University, Gujarat</p>
                                    </div>
                                </div> */}
                                <div className="brand-wrap">
                                    <div className="brand-media">
                                        <img src="assets/images/childrens_corner/Chinmayi_Tripathi.png" alt="Performer" />
                                        <h6>Chinmayi Tripathi</h6>
                                        <p>Singer
                                            Songwriter
                                            Poet</p>
                                    </div>
                                </div>
                                <div className="brand-wrap">
                                    <div className="brand-media">
                                        <img src="assets/images/childrens_corner/Girima_Gharekhan.png" alt="Performer" />
                                        <h6>Girima Gharekhan</h6>
                                        <p>Writer</p>
                                    </div>
                                </div>
                                <div className="brand-wrap">
                                    <div className="brand-media">
                                        <img src="assets/images/childrens_corner/Dr_Sangeeta_Angom.png" alt="Performer" />
                                        <h6>Dr. Sangeeta Angom</h6>
                                        <p>Scientist </p>
                                    </div>
                                </div>
                                <div className="brand-wrap">
                                    <div className="brand-media">
                                        <img src="assets/images/childrens_corner/Simran_Aggarwal.png" alt="Performer" />
                                        <h6>Simran Aggarwal</h6>
                                        <p>Project Associate</p>
                                    </div>
                                </div>
                                <div className="brand-wrap">
                                    <div className="brand-media">
                                        <img src="assets/images/childrens_corner/Dyutima_Sharma.png" alt="Performer" />
                                        <h6>Dyutima Sharma</h6>
                                        <p>Chief Clinical Psychologist <br /></p>
                                    </div>
                                </div>
                                <div className="brand-wrap">
                                    <div className="brand-media">
                                        <img src="assets/images/childrens_corner/Swati_Sengupta.png" alt="Performer" />
                                        <h6>Swati Sengupta</h6>
                                        <p>Author</p>
                                    </div>
                                </div>
                                <div className="brand-wrap">
                                    <div className="brand-media">
                                        <img src="assets/images/childrens_corner/Mukesh_Kumar.png" alt="Performer" />
                                        <h6>Mukesh Kumar</h6>
                                        <p>Calligrapher<br />
                                            Visual Artist</p>
                                    </div>
                                </div>
                                <div className="brand-wrap">
                                    <div className="brand-media">

                                        <img src="assets/images/childrens_corner/Vasudha_Ahuja.png" alt="Performer" />
                                        <h6>Vasudha Ahuja</h6>
                                        <p>Professional Storyteller</p>
                                    </div>
                                </div>


                            </Slider>


                        </div>
                    </div>

                </div>
                <img src='assets/images/elements/bg_ch_activity.png' alt='Child Activity' />

            </section>



            <section className='section-aboutUs'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2 className='section-heading mt-5'>About us</h2>
                            <h4 className='sub_title'>About National Book Trust (NBT)</h4>
                            <p className='mt-3'>The National Book Trust (NBT) is an apex body established in 1957 under the Ministry of Education, Government of India, with a mission to promote books and reading culture across the country. Over the decades, NBT has been instrumental in creating platforms that bring together authors, publishers, and readers, fostering a love for literature and encouraging the exchange of ideas.
                                Through its wide-ranging initiatives, including book publication, translation, and the organization of book fairs, NBT strives to make quality books accessible to readers of all age groups and linguistic backgrounds. The Trust plays a pivotal role in promoting Indian literature globally and supporting emerging writers, especially from regional and marginalized communities.</p>

                            <h4 className="mt-4 sub_title">Article</h4>
                            <p className="mt-3">Ahmedabad International Book Festival Goes Truly International</p>
                            <p className="mt-3">In a world where connections transcend borders, Ahmedabad stands as a vibrant cultural bridge between India and Spain. Known for its heritage and artistic traditions, the city has fostered deep ties with Valladolid, Spain, as twin cities since 2019.
                                These ties reflect not just shared cultural values but also a history of meaningful exchanges, exemplified by figures like Father Carlos Gonzalvez Valles.</p>
                            <NavLink className="view_more_btn " to="/blog">Read More</NavLink>
                        </div>
                    </div>
                </div>
            </section>

            <section id="" className='director_message mt-4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 text-center'>
                            <img src="assets/images/elements/img_chairman.png" className='chairman' alt="Chairman" />
                            <p>It is a privilege to host the Ahmedabad International Book Festival 2024, a platform for celebrating diverse voices and the transformative power of books. This festival is a tribute to readers, writers, and the universal love for stories. Let’s come together to ignite imaginations and foster a lifelong love for reading!</p>
                        </div>

                        <div className='col-md-6 text-center'>
                            <img src="assets/images/elements/img_director.png" className='director' alt="Director" />
                            <p>Welcome to the Ahmedabad International Book Festival 2024! This event celebrates the enduring power of books to educate, inspire, and connect people. Our mission is to bring readers, authors, and thinkers together to share stories and ideas that transcend borders. Join us to explore, learn, and celebrate the magic of literature!</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='section_floor_plan'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2 className='section-heading mt-5'>How to reach</h2>
                            <h6>The Ahmedabad International Book Festival 2024 floor plan is thoughtfully designed to ensure a seamless experience for visitors. It features dedicated zones for exhibition halls, author interactions, workshops, cultural performances, and food courts, all easily accessible and well-marked for convenience.</h6>
                            <img src="assets/images/elements/floor_plan.png" className='floor_plan w-100' alt="Floor Plan" />
                            <span className='download'><a className='download_btn' href='../assets/docs/Final_Layout_Plan.pdf' download="Final_Layout_Plan.pdf">Download PDF</a></span>
                            <span className='social_media d-none'>
                                <ul className="footer-social">
                                    <li>
                                        <NavLink to="#"><img className='' src="assets/images/social_media/twitter.png" alt="Twitter" /></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="https://www.instagram.com/aibf.nbt/profilecard/?igsh=YThuYm4xdml6bTF0" target='_blank'><img src="assets/images/social_media/instagram.png" alt="Instagram" /></NavLink>
                                    </li>
                                    <li className="">
                                        <NavLink to="https://www.youtube.com/@NBTIndia" target='_blank'><img src="assets/images/social_media/youtube.png" alt="Youtube" /></NavLink>
                                    </li>
                                    <li className="">
                                        <NavLink to="#"><img src="assets/images/social_media/facebook.png" alt="Facebook" /></NavLink>
                                    </li>
                                </ul>

                            </span>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}
