import React from 'react';
import InternalBanner from './InternalBanner';


export default function Gujrati() {
    return (
        <>
            <InternalBanner />
            <section className='gujrati_fonts my-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2 className='mt-5'>
                            લતા હરાણી</h2>
                            <h3 className='mt-4'>કવિયત્રી અને લેખિકા</h3>
                            <h3 className='mt-4'>રેડિયો-ચલચિત્રણીય કલાકાર</h3>
                            <p className='mt-4'>લતા હરાણી એક કવિયત્રી, નિખાલસ લેખિકા તેમજ રેડિયો-ચલચિત્રણીય કલાકાર છે. વધુમાં, તેઓ ગુજરાતી સાહિત્ય પરિષદની કાર્યકારી સમિતિના માનદ સભ્ય છે. વધુમાં, તેમની કલમે ૨૨ જેટલા પુસ્તકો લખાયા છે. વધુમાં, તેઓ ૨ આંતરરાષ્ટ્રીય અને ૬ રાષ્ટ્રીય તબક્કાના પુરસ્કારોથી પુરસ્કૃત કવિયત્રી છે.</p>
                           <h3 className='my-4'>તેમને મળેલ આંતરરાષ્ટ્રીય પુરસ્કારો
                           </h3>
                            <p><strong>૧.</strong> ‘સાહિત્ય સારંગ સન્માન’, બાલી-ઇન્ડોનેશિયા (આંતરરાષ્ટ્રીય હિન્દી સાહિત્ય પરિષદ દ્વારા)</p>
                            <p><strong>૨.</strong> ‘મહારાજા ચક્રધર સન્માન’ મિલાન-ઇટલી (આંતરરાષ્ટ્રીય હિન્દી સાહિત્ય પરિષદ દ્વારા)</p>

                            <h3 className='mt-4'>તેમના લેખનકાર્ય બદલ રાષ્ટ્રીય સ્તરે મળેલ પુરસ્કારો
                            </h3>
                            <p className='mt-4'><strong>૧.</strong> ‘ધણિકનો નિરાધાર’ - રાષ્ટ્રીય સાક્ષરતા મિશન</p>
                            <p><strong>૨.</strong> ‘ઉજાસનું પ્રથમ કિરણ’</p>
                            <p><strong>૩.</strong> ગુજરાત સાહિત્ય અકાદમી તરફથી વિવિધ પુરસ્કારોથી પુરસ્કૃત
                            </p>
                            <p><strong>૪.</strong> ગુજરાતી સાહિત્ય પરિષદ તેમજ સખી શક્તિ પુરસ્કારથી પુરસ્કૃત</p>
                            <p><strong>૫.</strong> તેમની કવિતા માટે ‘કલાગુરુ સન્માન’થી પુરસ્કૃત
                            </p>
                            <p><strong>૬.</strong> તેમના સમગ્ર સાહિત્યિક કાર્ય બદલ ‘સંસ્કારભારતી સન્માન’થી પુરસ્કૃત</p>



                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
