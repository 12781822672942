import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import { NavLink } from 'react-router-dom';

const NewHeader = () => {

    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;

            if (offset > 200) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);









    return (
        <header className="newHeader">


            <div className={`${isSticky ? 'sticky' : ''}`}>

                <Navbar />


            </div>



            <div className=''>
                <div class="social">
                    <ul className='side-view'>
                        <li class="twitter"><NavLink to="https://x.com/AIBF_NBT" target='_blank'><img className='' src="assets/images/social_media/twitter.png" alt="Twitter" /><span>Twitter</span></NavLink></li>
                        <li class="instagram"><NavLink to="https://www.instagram.com/aibf.nbt/" target='_blank'><img className='' src="assets/images/social_media/instagram.png" alt="Instagram" /><span>Instagram</span></NavLink></li>
                        <li class="google-plus"><NavLink to="https://www.youtube.com/@NBTIndia" target='_blank'><img className='' src="assets/images/social_media/youtube.png" alt="youtube" /><span>Youtube</span></NavLink></li>
                        <li class="facebook"><NavLink to="https://fb.me/e/5F8fd5Lso" target='_blank'><img className='' src="assets/images/social_media/facebook.png" alt="Twitter" /><span>Facebook</span></NavLink></li>
                    </ul>
                </div>
            </div>




        </header>


    )
}

export default NewHeader;
