import React, { useEffect, useState } from 'react';
import InternalBanner from './InternalBanner';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

export default function ChildernCorner() {

    const apiKey = process.env.REACT_APP_API_KEY;
    const baseURL = process.env.REACT_APP_BASE_URL;
    const [artists, setArtists] = useState();
    const [selectedArtist, setSelectedArtist] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const randomTexts = ["red", "green", "blue", "sky-blue", "yellow", "purple", "orange", "light-green"];
    const getRandomText = () => randomTexts[Math.floor(Math.random() * randomTexts.length)];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseURL}/get-all-children-resource`, {
                    headers: {
                        'X-API-KEY': apiKey,
                        accept: 'application/json',
                    },
                });
                console.log('Response:', response);
                setArtists(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [baseURL, apiKey]);


    const handleViewMore = (artist) => {
        setSelectedArtist(artist);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedArtist(null);
    };

    return (
        <>

            <InternalBanner />
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h2 class="section-heading mt-5">SCHEDULE FOR CHILDREN’S SESSIONS</h2>
                    </div>
                    <div className='col-md-12'>
                        <div class="card schedule_tabs" >

                            <div>
                                <div class="nav nav-tabs mb-0" id="nav-tab" role="tablist">
                                    <button class="nav-link active" id="day_1" data-bs-toggle="tab" data-bs-target="#nav-day_1" type="button" role="tab" aria-controls="nav-day_1" aria-selected="true">Day 1</button>
                                    <button class="nav-link" id="day_2" data-bs-toggle="tab" data-bs-target="#nav-day_2" type="button" role="tab" aria-controls="nav-day_2" aria-selected="false">Day 2</button>
                                    <button class="nav-link" id="day_3" data-bs-toggle="tab" data-bs-target="#nav-day_3" type="button" role="tab" aria-controls="nav-day_3" aria-selected="false">Day 3</button>
                                    <button class="nav-link" id="day_4" data-bs-toggle="tab" data-bs-target="#nav-day_4" type="button" role="tab" aria-controls="nav-day_4" aria-selected="true">Day 4</button>
                                    <button class="nav-link" id="day_5" data-bs-toggle="tab" data-bs-target="#nav-day_5" type="button" role="tab" aria-controls="nav-day_5" aria-selected="false">Day 5</button>
                                    <button class="nav-link" id="day_6" data-bs-toggle="tab" data-bs-target="#nav-day_6" type="button" role="tab" aria-controls="nav-day_6" aria-selected="false">Day 6</button>
                                    <button class="nav-link" id="day_7" data-bs-toggle="tab" data-bs-target="#nav-day_7" type="button" role="tab" aria-controls="nav-day_7" aria-selected="false">Day 7</button>
                                    <button class="nav-link" id="day_8" data-bs-toggle="tab" data-bs-target="#nav-day_8" type="button" role="tab" aria-controls="nav-day_8" aria-selected="false">Day 8</button>
                                    <button class="nav-link" id="day_9" data-bs-toggle="tab" data-bs-target="#nav-day_9" type="button" role="tab" aria-controls="nav-day_9" aria-selected="false">Day 9</button>
                                </div>
                            </div>
                            <div class="tab-content bg-light" id="nav-tabContent">
                                <div class="tab-pane fade active show" id="nav-day_1" role="tabpanel" aria-labelledby="nav-home-tab">
                                    <div class="container table-responsive">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark">
                                                <tr >
                                                    <th colspan="4">30th November 2024, Saturday</th>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Classes</th>
                                                    <th scope="col">Time</th>
                                                    <th scope="col">Session Name</th>
                                                    <th scope="col">Resource Person</th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1 to 5</td>
                                                    <td>10:00 AM – 10:40 AM</td>
                                                    <td>Bilingual Storytelling (Gujarati & Hindi)</td>
                                                    <td>Lata Hirani</td>
                                                </tr>
                                                <tr>
                                                    <td>1 to 12</td>
                                                    <td>10:40 AM – 11:00 AM</td>
                                                    <td>Orientation Session on Rashtriya e-Pustakalaya</td>
                                                    <td>Rashtriya e-Pustakalaya</td>

                                                </tr>
                                                <tr>
                                                    <td>1 to 5</td>
                                                    <td>11:00 AM – 11:40 AM</td>
                                                    <td>Gijubhai Ki Kahaniyan</td>
                                                    <td>Shivani Kanodia</td>

                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div class="tab-pane fade" id="nav-day_2" role="tabpanel" aria-labelledby="nav-profile-tab">
                                    <div class="container table-responsive">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark">
                                                <tr >
                                                    <th colspan="4">1st December 2024, Sunday</th>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Classes</th>
                                                    <th scope="col">Time</th>
                                                    <th scope="col">Session Name</th>
                                                    <th scope="col">Resource Person</th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1 to 5</td>
                                                    <td>10:00 AM – 10:40 AM</td>
                                                    <td>Feel, Think, Act- Theatre Workshop</td>
                                                    <td>Muskan & Farman </td>
                                                </tr>
                                                <tr>
                                                    <td>1 to 12</td>
                                                    <td>10:40 AM – 11:00 AM</td>
                                                    <td>Orientation Session on Rashtriya e-Pustakalaya</td>
                                                    <td>Rashtriya e-Pustakalaya</td>

                                                </tr>
                                                <tr>
                                                    <td>1 to 5</td>
                                                    <td>11:00 AM – 11:40 AM</td>
                                                    <td>Drama Discoveries</td>
                                                    <td>Muskan & Farman</td>

                                                </tr>

                                                <tr>
                                                    <td>1 to 5</td>
                                                    <td>12:00 PM – 12:40 PM</td>
                                                    <td>Big Problem Need Big Imagination: Storytelling Session</td>
                                                    <td>Sam &MI</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="nav-day_3" role="tabpanel" aria-labelledby="nav-contact-tab">
                                    <div class="container table-responsive">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark">
                                                <tr >
                                                    <th colspan="4">2nd December 2024, Monday</th>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Classes</th>
                                                    <th scope="col">Time</th>
                                                    <th scope="col">Session Name</th>
                                                    <th scope="col">Resource Person</th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1 to 5</td>
                                                    <td>10:00 AM – 10:40 AM</td>
                                                    <td>Katha-Kathan Session</td>
                                                    <td>Raksha Dave</td>
                                                </tr>
                                                <tr>
                                                    <td>1 to 12</td>
                                                    <td>10:40 AM – 11:00 AM</td>
                                                    <td>Orientation Session on Rashtriya e-Pustakalaya</td>
                                                    <td>Rashtriya e-Pustakalaya</td>

                                                </tr>
                                                <tr>
                                                    <td>5 to 11</td>
                                                    <td>11:00 AM – 11:40 AM</td>
                                                    <td>Junior CEOs: Business Management Activity </td>
                                                    <td>Janet's Little Genius Academy, Ahmd & IIM-Ahmd</td>

                                                </tr>

                                                <tr>
                                                    <td>6 to 12</td>
                                                    <td>12:00 PM – 12:40 PM</td>
                                                    <td>Unleashing Spanish: Your Path to Unlock Global Opportunities</td>
                                                    <td>Chhavi Gupta</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="nav-day_4" role="tabpanel" aria-labelledby="nav-contact-tab">
                                    <div class="container table-responsive">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark">
                                                <tr >
                                                    <th colspan="4">3rd December 2024, Tuesday</th>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Classes</th>
                                                    <th scope="col">Time</th>
                                                    <th scope="col">Session Name</th>
                                                    <th scope="col">Resource Person</th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1 to 5</td>
                                                    <td>10:00 AM – 10:40 AM</td>
                                                    <td>Musical Bal Kavita</td>
                                                    <td>Archan Trivedi </td>
                                                </tr>
                                                <tr>
                                                    <td>1 to 12</td>
                                                    <td>10:40 AM – 11:00 AM</td>
                                                    <td>Orientation Session on Rashtriya e-Pustakalaya</td>
                                                    <td>Rashtriya e-Pustakalaya</td>

                                                </tr>
                                                <tr>
                                                    <td>6 to 12</td>
                                                    <td>11:00 AM – 11:40 AM</td>
                                                    <td>Training Workshop in Science Communication</td>
                                                    <td>Gujarat Council of Science City</td>

                                                </tr>

                                                <tr>
                                                    <td>6 to 12</td>
                                                    <td>12:00 PM – 12:40 PM</td>
                                                    <td>Caricature Making Workshop on Gujarat's Culture</td>
                                                    <td>Jignesh Chavda</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="nav-day_5" role="tabpanel" aria-labelledby="nav-contact-tab">
                                    <div class="container table-responsive">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark">
                                                <tr >
                                                    <th colspan="4">4th December 2024, Wednesday</th>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Classes</th>
                                                    <th scope="col">Time</th>
                                                    <th scope="col">Session Name</th>
                                                    <th scope="col">Resource Person</th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1 to 5</td>
                                                    <td>10:00 AM – 10:40 AM</td>
                                                    <td>बुद्धि का कमाल - Gujarati Folktale </td>
                                                    <td>Seema Wahi Mukherje</td>
                                                </tr>
                                                <tr>
                                                    <td>1 to 12</td>
                                                    <td>10:40 AM – 11:00 AM</td>
                                                    <td>Orientation Session on Rashtriya e-Pustakalaya</td>
                                                    <td>Rashtriya e-Pustakalaya</td>

                                                </tr>
                                                <tr>
                                                    <td>1 to 5</td>
                                                    <td>11:00 AM – 11:40 AM</td>
                                                    <td>Balgeet: Folk Songs of Gujarat</td>
                                                    <td>Children's Research University</td>

                                                </tr>

                                                <tr>
                                                    <td>6 to 12</td>
                                                    <td>12:00 PM – 12:40 PM</td>
                                                    <td>Words that Sing: Poetry Writing Workshop</td>
                                                    <td>Chinmayi Tripathi</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="nav-day_6" role="tabpanel" aria-labelledby="nav-contact-tab">
                                    <div class="container table-responsive">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark">
                                                <tr >
                                                    <th colspan="4">5th December 2024, Thursday</th>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Classes</th>
                                                    <th scope="col">Time</th>
                                                    <th scope="col">Session Name</th>
                                                    <th scope="col">Resource Person</th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>5 to 8</td>
                                                    <td>10:00 AM – 10:40 AM</td>
                                                    <td>Let's Play Crossword</td>
                                                    <td>National Book Trust, India</td>
                                                </tr>
                                                <tr>
                                                    <td>1 to 12</td>
                                                    <td>10:40 AM – 11:00 AM</td>
                                                    <td>Orientation Session on Rashtriya e-Pustakalaya</td>
                                                    <td>Rashtriya e-Pustakalaya</td>

                                                </tr>
                                                <tr>
                                                    <td>1 to 5</td>
                                                    <td>11:00 AM – 11:40 AM</td>
                                                    <td>Folk Tales of Gujarat </td>
                                                    <td>Children's Research University</td>

                                                </tr>

                                                <tr>
                                                    <td>6 to 12</td>
                                                    <td>12:00 PM – 12:40 PM</td>
                                                    <td>Chandrayaan - 3: A Successful ISRO Mission</td>
                                                    <td>Vikram Sarabhai Space Exhibition Centre</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="nav-day_7" role="tabpanel" aria-labelledby="nav-contact-tab">
                                    <div class="container table-responsive">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th colspan="4">6th December 2024, Friday</th>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Classes</th>
                                                    <th scope="col">Time</th>
                                                    <th scope="col">Session Name</th>
                                                    <th scope="col">Resource Person</th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>5 to 8</td>
                                                    <td>10:00 AM – 10:40 AM</td>
                                                    <td>Gujarati Kavita Satr</td>
                                                    <td>Girima Gharekhan</td>
                                                </tr>
                                                <tr>
                                                    <td>1 to 12</td>
                                                    <td>10:40 AM – 11:00 AM</td>
                                                    <td>Orientation Session on Rashtriya e-Pustakalaya</td>
                                                    <td>Rashtriya e-Pustakalaya</td>

                                                </tr>
                                                <tr>
                                                    <td>4 to 7</td>
                                                    <td>11:00 AM – 11:40 AM</td>
                                                    <td>Math World</td>
                                                    <td>Janet's Little Genius Academy, Ahmd & IIM-Ahmd</td>

                                                </tr>

                                                <tr>
                                                    <td>5 to 12</td>
                                                    <td>12:00 PM – 12:40 PM</td>
                                                    <td>Lion of the Gir</td>
                                                    <td>Wildlife Institute of India</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="nav-day_8" role="tabpanel" aria-labelledby="nav-contact-tab">
                                    <div class="container table-responsive">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark">
                                                <tr >
                                                    <th colspan="4">7th December 2024, Saturday</th>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Classes</th>
                                                    <th scope="col">Time</th>
                                                    <th scope="col">Session Name</th>
                                                    <th scope="col">Resource Person</th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1 to 5</td>
                                                    <td>10:00 AM – 10:40 AM</td>
                                                    <td>Bhavnao ko Samjho: Emotional Intelligence Workshop </td>
                                                    <td>Dyutima Sharma</td>
                                                </tr>
                                                <tr>
                                                    <td>1 to 12</td>
                                                    <td>10:40 AM – 11:00 AM</td>
                                                    <td>Orientation Session on Rashtriya e-Pustakalaya</td>
                                                    <td>Rashtriya e-Pustakalaya</td>

                                                </tr>
                                                <tr>
                                                    <td>6 to 12</td>
                                                    <td>11:00 AM – 11:40 AM</td>
                                                    <td>Translation Workshop (English/Hindi to Gujarati) </td>
                                                    <td>Gujarat Sahitya Academy</td>

                                                </tr>

                                                <tr>
                                                    <td>7 to 9</td>
                                                    <td>12:00 PM – 12:40 PM</td>
                                                    <td>War, Peace & Independence (Author’s Session)</td>
                                                    <td>Swati Sengupta</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="nav-day_9" role="tabpanel" aria-labelledby="nav-contact-tab">
                                    <div class="container table-responsive">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark">
                                                <tr >
                                                    <th colspan="4">8th December 2024, Sunday</th>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Classes</th>
                                                    <th scope="col">Time</th>
                                                    <th scope="col">Session Name</th>
                                                    <th scope="col">Resource Person</th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1 to 5</td>
                                                    <td>10:00 AM – 10:40 AM</td>
                                                    <td>Raja Ka Raaz: Musical Storytelling </td>
                                                    <td>Vasudha Ahuja</td>
                                                </tr>
                                                <tr>
                                                    <td>1 to 12</td>
                                                    <td>10:40 AM – 11:00 AM</td>
                                                    <td>Orientation Session on Rashtriya e-Pustakalaya</td>
                                                    <td>Rashtriya e-Pustakalaya</td>

                                                </tr>
                                                <tr>
                                                    <td>1 to 5</td>
                                                    <td>11:00 AM – 11:40 AM</td>
                                                    <td>Gujarati Balgeet  </td>
                                                    <td>Kirit Goswami & Krushan Dave</td>

                                                </tr>

                                                <tr>
                                                    <td>6 to 12</td>
                                                    <td>12:00 PM – 12:40 PM</td>
                                                    <td>Devnagri Calligraphy Workshop</td>
                                                    <td>Mr Mukesh</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <span className='download mt-3'><a className='download_btn' href='../assets/docs/Schedule_Ahmedabad_Book_Festival_2024.pdf' download="Schedule_Ahmedabad_Book_Festival_2024.pdf">Download PDF</a></span>
                        </div>


                    </div>
                </div>
            </div>

            <section className='flim_schedule'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2 class="section-heading mt-5">CHILDREN FILM FESTIVAL SCHEDULE</h2>
                        </div>
                        <div className='col-md-12'>
                            <div class="card schedule_tabs" >

                                <div>
                                    <div class="nav nav-tabs mb-0" id="nav-tab" role="tablist">
                                        <button class="nav-link active" id="day_film_1" data-bs-toggle="tab" data-bs-target="#nav-film-day_1" type="button" role="tab" aria-controls="nav-day_1" aria-selected="true">Day 1</button>
                                        <button class="nav-link" id="day_film_2" data-bs-toggle="tab" data-bs-target="#nav-film-day_2" type="button" role="tab" aria-controls="nav-film-day_2" aria-selected="false">Day 2</button>
                                        <button class="nav-link" id="day_film_3" data-bs-toggle="tab" data-bs-target="#nav-film-day_3" type="button" role="tab" aria-controls="nav-film-day_3" aria-selected="false">Day 3</button>
                                        <button class="nav-link" id="day_film_4" data-bs-toggle="tab" data-bs-target="#nav-film-day_4" type="button" role="tab" aria-controls="nav-film-day_4" aria-selected="true">Day 4</button>
                                        <button class="nav-link" id="day_film_5" data-bs-toggle="tab" data-bs-target="#nav-film-day_5" type="button" role="tab" aria-controls="nav-film-day_5" aria-selected="false">Day 5</button>
                                        <button class="nav-link" id="day_film_6" data-bs-toggle="tab" data-bs-target="#nav-film-day_6" type="button" role="tab" aria-controls="nav-film-day_6" aria-selected="false">Day 6</button>
                                        <button class="nav-link" id="day_film_7" data-bs-toggle="tab" data-bs-target="#nav-film-day_7" type="button" role="tab" aria-controls="nav-film-day_7" aria-selected="false">Day 7</button>
                                        <button class="nav-link" id="day_film_8" data-bs-toggle="tab" data-bs-target="#nav-film-day_8" type="button" role="tab" aria-controls="nav-film-day_8" aria-selected="false">Day 8</button>
                                        <button class="nav-link" id="day_film_9" data-bs-toggle="tab" data-bs-target="#nav-film-day_9" type="button" role="tab" aria-controls="nav-film-day_9" aria-selected="false">Day 9</button>
                                    </div>
                                </div>
                                <div class="tab-content bg-light" id="nav-tabContent">
                                    <div class="tab-pane fade active show" id="nav-film-day_1" role="tabpanel" aria-labelledby="nav-home-tab">
                                        <div class="container table-responsive">

                                            <table class="table table-bordered table-hover">
                                                <thead class="thead-dark">
                                                    <tr >
                                                        <th colspan="8">30th November 2024 - Saturday - Opening Day - 10 AM to 2:10 PM </th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Sr. no.</th>
                                                        <th scope="col">Film</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Directed by</th>
                                                        <th scope="col">Runtime</th>
                                                        <th scope="col">Laungage</th>
                                                        <th scope="col">Time</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Big </td>
                                                        <td>Feature Film</td>
                                                        <td>Taiwan</td>
                                                        <td>Te-Sheng Wei</td>
                                                        <td>2:39:00 AM</td>
                                                        <td>Mandarin Chinese</td>
                                                        <td>10 am to 12:40 pm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Chhota Bheem And The Curse of Damyaan </td>
                                                        <td>Animation Film </td>
                                                        <td>India</td>
                                                        <td>-</td>
                                                        <td>1:07:00 AM</td>
                                                        <td>Hindi</td>
                                                        <td>1 pm to 2:10 pm</td>

                                                    </tr>

                                                </tbody>
                                            </table>

                                            <table class="table table-bordered table-hover">
                                                <thead class="thead-dark">
                                                    <tr >
                                                        <th colspan="8">30th November 2024 - Saturday - Opening Day - 4 PM to 5:25 PM</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Sr. no.</th>
                                                        <th scope="col">Film</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Directed by</th>
                                                        <th scope="col">Runtime</th>
                                                        <th scope="col">Laungage</th>
                                                        <th scope="col">Time</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Peepal</td>
                                                        <td>Short Film</td>
                                                        <td>India</td>
                                                        <td>Ganesh Hanmantrao Chowgule</td>
                                                        <td>0:32:45</td>
                                                        <td>Hindi</td>
                                                        <td>4 pm to 4:35 pm</td>
                                                    </tr>

                                                    <tr>
                                                        <td>2</td>
                                                        <td>The Sapien Urge</td>
                                                        <td>Short Film</td>
                                                        <td>India</td>
                                                        <td>Manas Mukesh Shete</td>
                                                        <td>3:08</td>
                                                        <td>English</td>
                                                        <td>4:35 pm to 4:40 pm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>Zafaraniyeh</td>
                                                        <td>Short Film</td>
                                                        <td>Iran, Islamic Republic of</td>
                                                        <td>Vahid Kermani</td>
                                                        <td>14:57</td>
                                                        <td>Persian</td>
                                                        <td>4:40 pm to 5 pm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>Super Heroes</td>
                                                        <td>Animation Film</td>
                                                        <td>Iran, Islamic Republic of</td>
                                                        <td>Abas Jalaliyekta</td>
                                                        <td>12:00</td>
                                                        <td>Persian</td>
                                                        <td>5 pm to 5:15 pm</td>

                                                    </tr>

                                                    <tr>
                                                        <td>5</td>
                                                        <td>Motor Cycles</td>
                                                        <td>Animation Film</td>
                                                        <td>Iran, Islamic Republic of</td>
                                                        <td>Hasan Asadi Khanouki</td>
                                                        <td>7:00</td>
                                                        <td>Persian</td>
                                                        <td>5:15 pm to 5:25 pm</td>
                                                    </tr>



                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div class="tab-pane fade" id="nav-film-day_2" role="tabpanel" aria-labelledby="nav-profile-tab">
                                        <div class="container table-responsive">
                                            <table class="table table-bordered table-hover">
                                                <thead class="thead-dark">
                                                    <tr >
                                                        <th colspan="8">1st December 2024 - Sunday - 10 AM to 1:40 PM</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Sr. no.</th>
                                                        <th scope="col">Film</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Directed by</th>
                                                        <th scope="col">Runtime</th>
                                                        <th scope="col">Laungage</th>
                                                        <th scope="col">Time</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Gold Medal</td>
                                                        <td>Short Film</td>
                                                        <td>United States</td>
                                                        <td>Ujjwal Patel</td>
                                                        <td>0:33:04</td>
                                                        <td>Hindi</td>
                                                        <td>10 am to 10:35 am</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Whisper to The Void</td>
                                                        <td>Student Film</td>
                                                        <td>China </td>
                                                        <td>Jiaxin Xu</td>
                                                        <td>15:00</td>
                                                        <td>Mandarin Chinese</td>
                                                        <td>10:35 am to 10:50 am</td>

                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>Seven Kingdoms</td>
                                                        <td>Animation Film</td>
                                                        <td>Russian Federation</td>
                                                        <td>Svetlana Boldina</td>
                                                        <td>7:00</td>
                                                        <td>Russian</td>
                                                        <td>10:50 am to 11 am</td>

                                                    </tr>

                                                    <tr>
                                                        <td>4</td>
                                                        <td>Axinos</td>
                                                        <td>Short Film</td>
                                                        <td>Greece</td>
                                                        <td>Anna Maria Kakona, Calliopi Villy Kotoula</td>
                                                        <td>5:00</td>
                                                        <td>Modern Greek</td>
                                                        <td>11 am to 11:05 am</td>

                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>Teca &amp; Tuti: A Night at the Library</td>
                                                        <td>Animation Film</td>
                                                        <td>Brazil</td>
                                                        <td>Eduardo Perdido, Tiago M. A. Lima, Diego M. Doimo</td>
                                                        <td>1:14:00</td>
                                                        <td>Portuguese</td>
                                                        <td>11:05 am to 12:20 pm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>Superbheem Aur Antariksh Ke Rakhwale</td>
                                                        <td>Animation Film</td>
                                                        <td>India</td>
                                                        <td>-</td>
                                                        <td>01:07:00</td>
                                                        <td>Hindi</td>
                                                        <td>12:30 pm to 1:40 pm</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table class="table table-bordered table-hover">
                                                <thead class="thead-dark">
                                                    <tr >
                                                        <th colspan="8">1st December 2024 - Sunday - 4 PM to 5:30 PM  </th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Sr. no.</th>
                                                        <th scope="col">Film</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Directed by</th>
                                                        <th scope="col">Runtime</th>
                                                        <th scope="col">Laungage</th>
                                                        <th scope="col">Time</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Khape</td>
                                                        <td>Feature Film</td>
                                                        <td>India</td>
                                                        <td>Suchita Bhhatia</td>
                                                        <td>1:25:00</td>
                                                        <td>Gujarati</td>
                                                        <td>4 pm to 5:30 pm</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="nav-film-day_3" role="tabpanel" aria-labelledby="nav-contact-tab">
                                        <div class="container table-responsive">
                                            <table class="table table-bordered table-hover">
                                                <thead class="thead-dark">
                                                    <tr >
                                                        <th colspan="8">2nd December 2024 - Monday - 10 AM to 1:50 PM</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Sr. no.</th>
                                                        <th>Film</th>
                                                        <th>Category</th>
                                                        <th>Country</th>
                                                        <th>Directed by</th>
                                                        <th>Runtime</th>
                                                        <th>Laungage</th>
                                                        <th>Time</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>The Storm</td>
                                                        <td>Animation Film</td>
                                                        <td>China</td>
                                                        <td>Zhigang aka &quot;Busifan&quot; YANG</td>
                                                        <td>1:42:09</td>
                                                        <td>Mandarin Chinese</td>
                                                        <td>10 am to 11:45 am</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Chhota Bheem and the Throne of Bali </td>
                                                        <td>Animation Film</td>
                                                        <td>India</td>
                                                        <td></td>
                                                        <td>01:50:00</td>
                                                        <td>Hindi</td>
                                                        <td>12 pm to 1:50 pm</td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                            <table class="table table-bordered table-hover">
                                                <thead class="thead-dark">
                                                    <tr >
                                                        <th colspan="8">2nd December 2024 - Monday - 10 AM to 1:50 PM</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Sr. no.</th>
                                                        <th>Film</th>
                                                        <th>Category</th>
                                                        <th>Country</th>
                                                        <th>Directed by</th>
                                                        <th>Runtime</th>
                                                        <th>Laungage</th>
                                                        <th>Time</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Home Cooking</td>
                                                        <td>Short Film</td>
                                                        <td>Turkey</td>
                                                        <td>Ahmet Toğaç</td>
                                                        <td>14:48</td>
                                                        <td>Turkish</td>
                                                        <td>4 pm to 4:15 pm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Half of a Fairy Tale</td>
                                                        <td>Student Film</td>
                                                        <td>China</td>
                                                        <td>Kailang WANG</td>
                                                        <td>18:32</td>
                                                        <td>Chinese</td>
                                                        <td>4:15 pm to 4:35 pm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>Chhoti Baarish</td>
                                                        <td>Short Film</td>
                                                        <td>India</td>
                                                        <td>Ritopriyo Saha</td>
                                                        <td>14:08</td>
                                                        <td>Hindi</td>
                                                        <td>4:35 pm to 4:50 pm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>Where are you from?</td>
                                                        <td>Short Film</td>
                                                        <td>Uruguay</td>
                                                        <td>Juan Cristiani</td>
                                                        <td>2:32</td>
                                                        <td>English</td>
                                                        <td>4:50 pm to 4:55 pm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>Aldaarrooj</td>
                                                        <td>Short Film</td>
                                                        <td>Oman</td>
                                                        <td>Hamid Saeed Al Ameri</td>
                                                        <td>7:30</td>
                                                        <td>Arabic</td>
                                                        <td>4:55 pm to 5:05 pm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>The Island </td>
                                                        <td>Documantary Film</td>
                                                        <td>Turkey</td>
                                                        <td>MAHMUT TAŞ</td>
                                                        <td>5:00</td>
                                                        <td>Turkish</td>
                                                        <td>5:05 pm to 5:10 pm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>7</td>
                                                        <td>Pigasus: The Lost Paradise - Battle for Evermore</td>
                                                        <td>Short Film</td>
                                                        <td>United States</td>
                                                        <td>Sean Patrick O&#39;Reilly</td>
                                                        <td>3:44</td>
                                                        <td>English</td>
                                                        <td>5:10 pm to 5:15 pm </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="nav-film-day_4" role="tabpanel" aria-labelledby="nav-contact-tab">
                                        <div class="container table-responsive">
                                            <table class="table table-bordered table-hover">
                                                <thead class="thead-dark">
                                                    <tr >
                                                        <th colspan="8">3rd December 2024 - Tuesday - 10 AM to 12:50 PM  </th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Sr. no.</th>
                                                        <th scope="col">Film</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Directed by</th>
                                                        <th scope="col">Runtime</th>
                                                        <th scope="col">Laungage</th>
                                                        <th scope="col">Time</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Ballad of the mountain</td>
                                                        <td>Short Film</td>
                                                        <td>India </td>
                                                        <td>Tarun Jain</td>
                                                        <td>16:40</td>
                                                        <td>Hindi</td>
                                                        <td>10 am to 10:20 am</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="s6" dir="ltr">2</td>
                                                        <td class="s7">Ice Cream</td>
                                                        <td class="s7">Short Film</td>
                                                        <td class="s7">Iran, Islamic Republic of</td>
                                                        <td class="s7">Farbod Alinaghian</td>
                                                        <td class="s8">6:29</td>
                                                        <td class="s7">Persian</td>
                                                        <td class="s7" dir="ltr">10:20 am to 10:25 am</td>

                                                    </tr>
                                                    <tr>
                                                        <td class="s6" dir="ltr">3</td>
                                                        <td class="s7">The Alchemist’s Apprentice</td>
                                                        <td class="s7">Animation Film</td>
                                                        <td class="s7">Iran, Islamic Republic of</td>
                                                        <td class="s7">Erfan Parsapour</td>
                                                        <td class="s8">6:48</td>
                                                        <td class="s7">No Dialogue</td>
                                                        <td class="s7" dir="ltr">10:25 am to 10:30 am</td>

                                                    </tr>

                                                    <tr>
                                                        <td class="s6" dir="ltr">4</td>
                                                        <td class="s7">Desa Timun (The Cucumber Village)</td>
                                                        <td class="s7">Short Film</td>
                                                        <td class="s7">Indonesia</td>
                                                        <td class="s7">Daud Nugraha</td>
                                                        <td class="s8">4:08</td>
                                                        <td class="s7">Indonesian</td>
                                                        <td class="s7" dir="ltr">10:30 am to 10:35 am</td>

                                                    </tr>
                                                    <tr>
                                                        <td class="s6" dir="ltr">5</td>
                                                        <td class="s7">Roll No.52</td>
                                                        <td class="s7">Student Film</td>
                                                        <td class="s7">India</td>
                                                        <td class="s7">Saroj Kumar</td>
                                                        <td class="s8">20:00</td>
                                                        <td class="s7">Telugu</td>
                                                        <td class="s7" dir="ltr">10:35 am to 10:55 am</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="s6" dir="ltr">6</td>
                                                        <td class="s7">The Jockey</td>
                                                        <td class="s7">Short Film</td>
                                                        <td class="s7">Iran, Islamic Republic of</td>
                                                        <td class="s7">HADI SHARIATI</td>
                                                        <td class="s8">0:30:00</td>
                                                        <td class="s19">Persian</td>
                                                        <td class="s7" dir="ltr">10:55 am to 11:25 am</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="s9">7</td>
                                                        <td class="s10">Planet Toyz</td>
                                                        <td class="s10">Animation Film</td>
                                                        <td class="s10">India</td>
                                                        <td class="s7"></td>
                                                        <td class="s10">01:04:00</td>
                                                        <td class="s10">Hindi</td>
                                                        <td class="s10">11:45 am to 12:50 pm</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table class="table table-bordered table-hover">
                                                <thead class="thead-dark">
                                                    <tr >
                                                        <th colspan="8">3rd December 2024 - Tuesday - 10 AM to 12:50 PM </th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Sr. no.</th>
                                                        <th scope="col">Film</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Directed by</th>
                                                        <th scope="col">Runtime</th>
                                                        <th scope="col">Laungage</th>
                                                        <th scope="col">Time</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="s6" dir="ltr">1</td>
                                                        <td class="s7">In the Arms of a Tree</td>
                                                        <td class="s7">Feature Film</td>
                                                        <td class="s7">Iran, Islamic Republic of</td>
                                                        <td class="s7">Babak Khajehpasha</td>
                                                        <td class="s8">1:22:52</td>
                                                        <td class="s7">Persian</td>
                                                        <td class="s7" dir="ltr">4 pm to 5:25 pm</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="nav-film-day_5" role="tabpanel" aria-labelledby="nav-contact-tab">
                                        <div class="container table-responsive">
                                            <table class="table table-bordered table-hover">
                                                <thead class="thead-dark">
                                                    <tr >
                                                        <th colspan="8">4th December 2024 - Wednesday - 10 AM to 2 PM</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Sr. no.</th>
                                                        <th scope="col">Film</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Directed by</th>
                                                        <th scope="col">Runtime</th>
                                                        <th scope="col">Laungage</th>
                                                        <th scope="col">Time</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="s6">1</td>
                                                        <td class="s7">Soaring Wings</td>
                                                        <td class="s7">Feature Film</td>
                                                        <td class="s7">India</td>
                                                        <td class="s7">Palash Das</td>
                                                        <td class="s8">1:33:35</td>
                                                        <td class="s7">Bengali</td>
                                                        <td class="s7">10 am to 11:40 am</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="s9">2</td>
                                                        <td class="s10">Chhota Bheem Kungfu Dhamaka</td>
                                                        <td class="s10">Animation Film</td>
                                                        <td class="s10">India</td>
                                                        <td class="s7"></td>
                                                        <td class="s10">01:54:00</td>
                                                        <td class="s10">Hindi</td>
                                                        <td class="s10">12 pm to 2 pm</td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                            <table class="table table-bordered table-hover">
                                                <thead class="thead-dark">
                                                    <tr >
                                                        <th colspan="8">4th December 2024 - Wednesday - 4 PM to 5:10 PM</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Sr. no.</th>
                                                        <th scope="col">Film</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Directed by</th>
                                                        <th scope="col">Runtime</th>
                                                        <th scope="col">Laungage</th>
                                                        <th scope="col">Time</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="s6" dir="ltr">1</td>
                                                        <td class="s7">Felix the Fox</td>
                                                        <td class="s7">Short Film</td>
                                                        <td class="s7">United States</td>
                                                        <td class="s7">Teddy Young</td>
                                                        <td class="s8">18:16</td>
                                                        <td class="s7">English</td>
                                                        <td class="s7" dir="ltr">4 pm to 4:20 pm</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="s6" dir="ltr">2</td>
                                                        <td class="s7">Sue</td>
                                                        <td class="s7">Short Film</td>
                                                        <td class="s7">Iran, Islamic Republic of</td>
                                                        <td class="s7">Mohsen Khodabandeh</td>
                                                        <td class="s8">11:12</td>
                                                        <td class="s7">Turkish</td>
                                                        <td class="s7" dir="ltr">4:20 pm to 4:35 pm</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="s6" dir="ltr">3</td>
                                                        <td class="s7">Today We Climbed a Hill</td>
                                                        <td class="s7">Animation Film</td>
                                                        <td class="s7">United States</td>
                                                        <td class="s7">Gordon LePage</td>
                                                        <td class="s8">7:31</td>
                                                        <td class="s7">No Dialogue</td>
                                                        <td class="s7" dir="ltr">4:35 pm to 4:45 pm</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="s6" dir="ltr">4</td>
                                                        <td class="s7">MeteR</td>
                                                        <td class="s7">Student Film</td>
                                                        <td class="s7">China</td>
                                                        <td class="s7">Tianyue Fu</td>
                                                        <td class="s8">5:41</td>
                                                        <td class="s7">Chinese</td>
                                                        <td class="s7" dir="ltr">4:45 pm to 4:50 pm</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="s6" dir="ltr">5</td>
                                                        <td class="s7">Black rain in my eyes</td>
                                                        <td class="s7">Documantary Film</td>
                                                        <td class="s7">Syrian Arab Republic</td>
                                                        <td class="s10">Amir Athar Soheili, Amir Masoud Soheili</td>
                                                        <td class="s8">20:00</td>
                                                        <td class="s7">Arabic</td>
                                                        <td class="s7" dir="ltr">4:50 pm to 5:10 pm</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="nav-film-day_6" role="tabpanel" aria-labelledby="nav-contact-tab">
                                        <div class="container table-responsive">
                                            <table class="table table-bordered table-hover">
                                                <thead class="thead-dark">
                                                    <tr >
                                                        <th colspan="8">5th December 2024 - Thursday - 10 AM to 12:40 PM</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Sr. no.</th>
                                                        <th scope="col">Film</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Directed by</th>
                                                        <th scope="col">Runtime</th>
                                                        <th scope="col">Laungage</th>
                                                        <th scope="col">Time</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="s6">1</td>
                                                        <td class="s7">Knot</td>
                                                        <td class="s7">Short Film</td>
                                                        <td class="s7">India </td>
                                                        <td class="s7">Abhay Parveen Gupta</td>
                                                        <td class="s8">14:30</td>
                                                        <td class="s7">Hindi</td>
                                                        <td class="s7" dir="ltr">10 am to 10:15 am</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="s6">2</td>
                                                        <td class="s7">Golu</td>
                                                        <td class="s7">Short Film</td>
                                                        <td class="s7">India</td>
                                                        <td class="s7">Raj kumar Pal</td>
                                                        <td class="s8">22:07</td>
                                                        <td class="s7">Bengali</td>
                                                        <td class="s7" dir="ltr">10:15 am to 10:40 am</td>

                                                    </tr>
                                                    <tr>
                                                        <td class="s6">3</td>
                                                        <td class="s7">Saudade</td>
                                                        <td class="s7">Short Film</td>
                                                        <td class="s7">Iran, Islamic Republic of</td>
                                                        <td class="s7" dir="ltr">Mehrdad Azizi Parsa</td>
                                                        <td class="s8">11:28</td>
                                                        <td class="s7">Persian</td>
                                                        <td class="s7" dir="ltr">10:40 am to 10:50 am</td>

                                                    </tr>

                                                    <tr>
                                                        <td class="s6">4</td>
                                                        <td class="s7">Behrupiya</td>
                                                        <td class="s7">Short Film</td>
                                                        <td class="s7">India</td>
                                                        <td class="s7">Bhasker Vishwanathan</td>
                                                        <td class="s8">20:00</td>
                                                        <td class="s7">Hindi</td>
                                                        <td class="s7" dir="ltr">10:50 am to 11:10 am</td>

                                                    </tr>
                                                    <tr>
                                                        <td class="s6" dir="ltr">5</td>
                                                        <td class="s7">VISION: A New Direction</td>
                                                        <td class="s7">Student Film</td>
                                                        <td class="s7">India</td>
                                                        <td class="s7">Hridaan Sharma</td>
                                                        <td class="s8">6:24</td>
                                                        <td class="s7">English, Hindi</td>
                                                        <td class="s7" dir="ltr">11:10 am to 11:15 am</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="s9">6</td>
                                                        <td class="s10">Chhota Bheem - Chhota Bheem aur Mayavi Gorgan</td>
                                                        <td class="s10">Animation Film</td>
                                                        <td class="s10">India</td>
                                                        <td class="s7"></td>
                                                        <td class="s10">01:08:00</td>
                                                        <td class="s10">Hindi</td>
                                                        <td class="s10">11:30 to 12:40 pm</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table class="table table-bordered table-hover">
                                                <thead class="thead-dark">
                                                    <tr >
                                                        <th colspan="8">5th December 2024 - Thursday - 4 PM to 5:35 PM</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Sr. no.</th>
                                                        <th scope="col">Film</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Directed by</th>
                                                        <th scope="col">Runtime</th>
                                                        <th scope="col">Laungage</th>
                                                        <th scope="col">Time</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="s6" dir="ltr">1</td>
                                                        <td class="s7">A Passenger From Ganora</td>
                                                        <td class="s7">Animation Film</td>
                                                        <td class="s7">Iran, Islamic Republic of</td>
                                                        <td class="s7">Seyed Ahmad Alamdar</td>
                                                        <td class="s8">1:34:13</td>
                                                        <td class="s19">Persian</td>
                                                        <td class="s7" dir="ltr">4 pm to 5:35 pm</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="nav-film-day_7" role="tabpanel" aria-labelledby="nav-contact-tab">
                                        <div class="container table-responsive">
                                            <table class="table table-bordered table-hover">
                                                <thead class="thead-dark">
                                                    <tr >
                                                        <th colspan="8">6th December 2024 - Friday - 10 AM to 1:10 PM</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Sr. no.</th>
                                                        <th scope="col">Film</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Directed by</th>
                                                        <th scope="col">Runtime</th>
                                                        <th scope="col">Laungage</th>
                                                        <th scope="col">Time</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="s6">1</td>
                                                        <td class="s7">Masti Ni Pathshala</td>
                                                        <td class="s7">Feature Film</td>
                                                        <td class="s7">India</td>
                                                        <td class="s7">Sunil Vaghela</td>
                                                        <td class="s8">1:47:00</td>
                                                        <td class="s7">Gujarati</td>
                                                        <td class="s7">10 am to 11:50 am</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="s10">2</td>
                                                        <td class="s10">Chhota Bheem- Mission Mangalyan </td>
                                                        <td class="s10">Animation Film</td>
                                                        <td class="s10">India</td>
                                                        <td class="s7"></td>
                                                        <td class="s10">1:10:00</td>
                                                        <td class="s10">Hindi</td>
                                                        <td class="s10">12 pm to 1:10 pm</td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                            <table class="table table-bordered table-hover">
                                                <thead class="thead-dark">
                                                    <tr >
                                                        <th colspan="8">6th December 2024 - Friday - 4 PM to 5 PM</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Sr. no.</th>
                                                        <th scope="col">Film</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Directed by</th>
                                                        <th scope="col">Runtime</th>
                                                        <th scope="col">Laungage</th>
                                                        <th scope="col">Time</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="s6" dir="ltr">1</td>
                                                        <td class="s7">The Ascent of Mt. Meru</td>
                                                        <td class="s7">Documantary Film</td>
                                                        <td class="s7">India </td>
                                                        <td class="s7">Ronak Singh</td>
                                                        <td class="s8">0:40:00</td>
                                                        <td class="s7">English, Hindi, Marathi</td>
                                                        <td class="s7" dir="ltr">4 pm to 4:40 pm</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="s6" dir="ltr">2</td>
                                                        <td class="s7">Bottles</td>
                                                        <td class="s7">Short Film</td>
                                                        <td class="s7">Morocco</td>
                                                        <td class="s7">Yassine EL Idrissi</td>
                                                        <td class="s8">18:00</td>
                                                        <td class="s7">Arabic</td>
                                                        <td class="s7" dir="ltr">4:40 pm to 5 pm</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="nav-film-day_8" role="tabpanel" aria-labelledby="nav-contact-tab">
                                        <div class="container table-responsive">
                                            <table class="table table-bordered table-hover">
                                                <thead class="thead-dark">
                                                    <tr >
                                                        <th colspan="8">7th December 2024 - Saturday - 10 AM to 12:50 PM</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Sr. no.</th>
                                                        <th scope="col">Film</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Directed by</th>
                                                        <th scope="col">Runtime</th>
                                                        <th scope="col">Laungage</th>
                                                        <th scope="col">Time</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="s6" dir="ltr">1</td>
                                                        <td class="s7">The First Film</td>
                                                        <td class="s7">Short Film</td>
                                                        <td class="s7">India</td>
                                                        <td class="s7">Piyush Thakur</td>
                                                        <td class="s8">0:24:00</td>
                                                        <td class="s7">Hindi</td>
                                                        <td class="s7" dir="ltr">10 am to 10:25 am</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="s6" dir="ltr">2</td>
                                                        <td class="s7">Nest</td>
                                                        <td class="s7">Student Film</td>
                                                        <td class="s7">India</td>
                                                        <td class="s7">Asmita Pal</td>
                                                        <td class="s8">21:33</td>
                                                        <td class="s7">Bengali</td>
                                                        <td class="s7" dir="ltr">10:25 am to 10:50 am</td>

                                                    </tr>
                                                    <tr>
                                                        <td class="s6" dir="ltr">3</td>
                                                        <td class="s7">The Tongue-tied</td>
                                                        <td class="s7">Short Film</td>
                                                        <td class="s7">Iran, Islamic Republic of</td>
                                                        <td class="s7">Ali Fard</td>
                                                        <td class="s8">6:57</td>
                                                        <td class="s7">Persian</td>
                                                        <td class="s7" dir="ltr">10:50 am to 11 am</td>

                                                    </tr>

                                                    <tr>
                                                        <td class="s6" dir="ltr">4</td>
                                                        <td class="s7">Thar Be Pirates</td>
                                                        <td class="s7">Short Film</td>
                                                        <td class="s7">United States</td>
                                                        <td class="s7">Julia Rudnitzky</td>
                                                        <td class="s8">14:28</td>
                                                        <td class="s7">English</td>
                                                        <td class="s7" dir="ltr">11 am to 11:15 am</td>

                                                    </tr>
                                                    <tr>
                                                        <td class="s6" dir="ltr">5</td>
                                                        <td class="s7">Waiting for Sea Serpents</td>
                                                        <td class="s7">Short Film</td>
                                                        <td class="s7">United States</td>
                                                        <td class="s7">Ben Tobin</td>
                                                        <td class="s8">5:00</td>
                                                        <td class="s7">English</td>
                                                        <td class="s7" dir="ltr">11:15 am to 11:20 am</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="s9">6</td>
                                                        <td class="s10">Main hu Super Bheem</td>
                                                        <td class="s10">Animation Film </td>
                                                        <td class="s10">India</td>
                                                        <td class="s7"></td>
                                                        <td class="s10">01:08:00</td>
                                                        <td class="s10">Hindi</td>
                                                        <td class="s10">11:30 am to 12:50 pm</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table class="table table-bordered table-hover">
                                                <thead class="thead-dark">
                                                    <tr >
                                                        <th colspan="8">7th December 2024 - Saturday - 4 PM to 5:40 PM</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Sr. no.</th>
                                                        <th scope="col">Film</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Directed by</th>
                                                        <th scope="col">Runtime</th>
                                                        <th scope="col">Laungage</th>
                                                        <th scope="col">Time</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="s6" dir="ltr">1</td>
                                                        <td class="s7">Lidong&#39;s Summer</td>
                                                        <td class="s7">Feature Film</td>
                                                        <td class="s7">China</td>
                                                        <td class="s7">Weijun Zhang, Nannan Bai</td>
                                                        <td class="s8">1:35:41</td>
                                                        <td class="s7">Chinese</td>
                                                        <td class="s7" dir="ltr">4 pm to 5:40 pm</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="nav-film-day_9" role="tabpanel" aria-labelledby="nav-contact-tab">
                                        <div class="container table-responsive">
                                            <table class="table table-bordered table-hover">
                                                <thead class="thead-dark">
                                                    <tr >
                                                        <th colspan="8">8th December 2024 - Sunday - Closing Day - 10 AM to 12:40 PM</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Sr. no.</th>
                                                        <th scope="col">Film</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Directed by</th>
                                                        <th scope="col">Runtime</th>
                                                        <th scope="col">Laungage</th>
                                                        <th scope="col">Time</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="s6" dir="ltr">1</td>
                                                        <td class="s7">Lost</td>
                                                        <td class="s7">Short Film</td>
                                                        <td class="s7">Lithuania</td>
                                                        <td class="s7">Indrė Mickus</td>
                                                        <td class="s8">14:36</td>
                                                        <td class="s7">Lithuanian</td>
                                                        <td class="s7" dir="ltr">10 am to 10:15 am</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="s6" dir="ltr">2</td>
                                                        <td class="s7">Bourj Stories</td>
                                                        <td class="s7">Documantary Film</td>
                                                        <td class="s7">Lebanon</td>
                                                        <td class="s7">Sarah Gross</td>
                                                        <td class="s8">14:16</td>
                                                        <td class="s7">Arabic</td>
                                                        <td class="s7" dir="ltr">10:15 am to 10:30 am</td>

                                                    </tr>
                                                    <tr>
                                                        <td class="s6" dir="ltr">3</td>
                                                        <td class="s7">AhmadReza Buffon</td>
                                                        <td class="s7">Short Film</td>
                                                        <td class="s7">Iran, Islamic Republic of</td>
                                                        <td class="s7">Morteza Shahbazi</td>
                                                        <td class="s25">11:42</td>
                                                        <td class="s19">Persian</td>
                                                        <td class="s7" dir="ltr">10:30 am to 10:40 am</td>

                                                    </tr>

                                                    <tr>
                                                        <td class="s6" dir="ltr">4</td>
                                                        <td class="s7">Kevin Patel - At Your Service</td>
                                                        <td class="s7">Short Film</td>
                                                        <td class="s7">United States</td>
                                                        <td class="s10">Jay Shirish Raval, Abhishek Amit Soniminde</td>
                                                        <td class="s8">0:25:00</td>
                                                        <td class="s7">Gujarati</td>
                                                        <td class="s7" dir="ltr">10:40 am to 11:05 am</td>

                                                    </tr>
                                                    <tr>
                                                        <td class="s6" dir="ltr">5</td>
                                                        <td class="s7">Money Transfer</td>
                                                        <td class="s7">Short Film</td>
                                                        <td class="s7">Iran, Islamic Republic of</td>
                                                        <td class="s7">Mohammad Nabi Talebi</td>
                                                        <td class="s8">2:44</td>
                                                        <td class="s7">Persian</td>
                                                        <td class="s7" dir="ltr">11:05 to 11:10 am</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="s6" dir="ltr">6</td>
                                                        <td class="s7">Birthday Cake</td>
                                                        <td class="s7">Short Film</td>
                                                        <td class="s7">Iran, Islamic Republic of</td>
                                                        <td class="s7">Vahid Ansari</td>
                                                        <td class="s8">1:40</td>
                                                        <td class="s7">Persian</td>
                                                        <td class="s7" dir="ltr">11:10 to 11:15 am </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="s6" dir="ltr">7</td>
                                                        <td class="s7">The Paper on the Window</td>
                                                        <td class="s7">Short Film</td>
                                                        <td class="s7">Iran, Islamic Republic of</td>
                                                        <td class="s7">Hosein Fereydoonjah</td>
                                                        <td class="s8">3:19</td>
                                                        <td class="s7">Persian</td>
                                                        <td class="s7" dir="ltr">11:15 am to 11:20 am</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="s9">8</td>
                                                        <td class="s10">Fire and Ice </td>
                                                        <td class="s10">Animation Film</td>
                                                        <td class="s10">India</td>
                                                        <td class="s7"></td>
                                                        <td class="s10">01:07:00</td>
                                                        <td class="s10">Hindi</td>
                                                        <td class="s10">11:30 am to 12:40 pm</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table class="table table-bordered table-hover">
                                                <thead class="thead-dark">
                                                    <tr >
                                                        <th colspan="8">1st December 2024 - Sunday - 10 AM to 1:40 PM</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Sr. no.</th>
                                                        <th scope="col">Film</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Directed by</th>
                                                        <th scope="col">Runtime</th>
                                                        <th scope="col">Laungage</th>
                                                        <th scope="col">Time</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="s6">1</td>
                                                        <td class="s7">Selfie with Rostam</td>
                                                        <td class="s7">Feature Film</td>
                                                        <td class="s7">Iran, Islamic Republic of</td>
                                                        <td class="s7">HOSSEIN Ghana&#39;at</td>
                                                        <td class="s8">1:25:00</td>
                                                        <td class="s7">Persian</td>
                                                        <td class="s7" dir="ltr">4 pm to 5:25 pm</td>
                                                    </tr>



                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>


                            </div>


                        </div>
                    </div>
                </div>
            </section>
            



            <section className='artist pb-5 d-none'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2 class="section-heading mt-5">CHILDREN FILM FESTIVAL SCHEDULE</h2>
                        </div>

                      
                        <div className='col-md-6 col-xl-4'>
                            <div className='performer'>
                                <div className='row mx-0'>
                                    <div className='col-md-4'><img src="assets/images/childrens_corner/Lata_Hirani.png" className='w-100' alt="Performer" /></div>
                                    <div className='col-md-8'>
                                        <h6>Lata Hirani</h6>
                                        <p>Poet, Writer, Radio-TV Artist</p>
                                        <NavLink className='view_more_btn' to="#">View More</NavLink>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-6 col-xl-4'>
                            <div className='performer border-yellow'>
                                <div className='row mx-0'>
                                    <div className='col-md-4'><img src="assets/images/childrens_corner/Shivani_Kanodia.png" className='w-100' alt="Performer" /></div>
                                    <div className='col-md-8'>
                                        <h6>Shivani Kanodia</h6>
                                        <p>Professional Storyteller</p>
                                        <NavLink className='view_more_btn' to="#">View More</NavLink>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-6 col-xl-4'>
                            <div className='performer border-green'>
                                <div className='row mx-0'>
                                    <div className='col-md-4'><img src="assets/images/childrens_corner/Muskan_Sharma.png" className='w-100' alt="Performer" /></div>
                                    <div className='col-md-8'>
                                        <h6>Muskan Sharma</h6>
                                        <p>Theatre Artist Facilitator </p>
                                        <NavLink className='view_more_btn' to="#">View More</NavLink>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-6 col-xl-4'>
                            <div className='performer border-red'>
                                <div className='row mx-0'>
                                    <div className='col-md-4'><img src="assets/images/childrens_corner/Farman.png" className='w-100' alt="Performer" /></div>
                                    <div className='col-md-8'>
                                        <h6>Farman</h6>
                                        <p>Theatre Artist-Facilitator</p>
                                        <NavLink className='view_more_btn' to="#">View More</NavLink>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-6 col-xl-4'>
                            <div className='performer border-purple'>
                                <div className='row mx-0'>
                                    <div className='col-md-4'><img src="assets/images/childrens_corner/Raksha_Dave.png" className='w-100' alt="Performer" /></div>
                                    <div className='col-md-8'>
                                        <h6>Raksha Dave</h6>
                                        <p>Renowned Poet</p>
                                        <NavLink className='view_more_btn' to="#">View More</NavLink>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-6 col-xl-4'>
                            <div className='performer border-light-green'>
                                <div className='row mx-0'>
                                    <div className='col-md-4'><img src="assets/images/childrens_corner/Grishma_Solanki.png" className='w-100' alt="Performer" /></div>
                                    <div className='col-md-8'>
                                        <h6>Grishma Solanki</h6>
                                        <p>Subject Matter</p>
                                        <NavLink className='view_more_btn' to="#">View More</NavLink>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-6 col-xl-4'>
                            <div className='performer border-blue'>
                                <div className='row mx-0'>
                                    <div className='col-md-4'><img src="assets/images/childrens_corner/Chhavi_Gupta.png" className='w-100' alt="Performer" /></div>
                                    <div className='col-md-8'>
                                        <h6>Chhavi Gupta</h6>
                                        <p>Linguist Sociologist</p>
                                        <NavLink className='view_more_btn' to="#">View More</NavLink>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-6 col-xl-4'>
                            <div className='performer border-red'>
                                <div className='row mx-0'>
                                    <div className='col-md-4'><img src="assets/images/childrens_corner/Jignesh_Chavda.png" className='w-100' alt="Performer" /></div>
                                    <div className='col-md-8'>
                                        <h6>Jignesh Chavda</h6>
                                        <p>Animator Illustrator</p>
                                        <NavLink className='view_more_btn' to="#">View More</NavLink>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-6 col-xl-4'>
                            <div className='performer border-sky-blue'>
                                <div className='row mx-0'>
                                    <div className='col-md-4'><img src="assets/images/childrens_corner/Seema_Wahi_Mukherjee.png" className='w-100' alt="Performer" /></div>
                                    <div className='col-md-8'>
                                        <h6>Seema Wahi Mukherjee</h6>
                                        <p>Storytelling Coach</p>
                                        <NavLink className='view_more_btn' to="#">View More</NavLink>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-6 col-xl-4'>
                            <div className='performer border-orange'>
                                <div className='row mx-0'>
                                    <div className='col-md-4'><img src="assets/images/childrens_corner/Childrens_Research_University.png" className='w-100' alt="Performer" /></div>
                                    <div className='col-md-8'>
                                        <h6>Children's Research University</h6>
                                        <p>State University, Gujarat</p>
                                        <NavLink className='view_more_btn' to="#">View More</NavLink>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-6 col-xl-4'>
                            <div className='performer border-yellow'>
                                <div className='row mx-0'>
                                    <div className='col-md-4'><img src="assets/images/childrens_corner/Chinmayi_Tripathi.png" className='w-100' alt="Performer" /></div>
                                    <div className='col-md-8'>
                                        <h6>Chinmayi Tripathi</h6>
                                        <p>Singer,
                                            Songwriter,
                                            Poet</p>
                                        <NavLink className='view_more_btn' to="#">View More</NavLink>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-6 col-xl-4'>
                            <div className='performer border-green'>
                                <div className='row mx-0'>
                                    <div className='col-md-4'><img src="assets/images/childrens_corner/Girima_Gharekhan.png" className='w-100' alt="Performer" /></div>
                                    <div className='col-md-8'>
                                        <h6>Girima Gharekhan</h6>
                                        <p>Writer</p>
                                        <NavLink className='view_more_btn' to="#">View More</NavLink>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-6 col-xl-4'>
                            <div className='performer border-purple'>
                                <div className='row mx-0'>
                                    <div className='col-md-4'><img src="assets/images/childrens_corner/Dr_Sangeeta_Angom.png" className='w-100' alt="Performer" /></div>
                                    <div className='col-md-8'>
                                        <h6>Dr. Sangeeta Angom</h6>
                                        <p>Scientist </p>
                                        <NavLink className='view_more_btn' to="#">View More</NavLink>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-6 col-xl-4'>
                            <div className='performer'>
                                <div className='row mx-0'>
                                    <div className='col-md-4'><img src="assets/images/childrens_corner/Simran_Aggarwal.png" className='w-100' alt="Performer" /></div>
                                    <div className='col-md-8'>
                                        <h6>Simran Aggarwal</h6>
                                        <p>Project Associate</p>
                                        <NavLink className='view_more_btn' to="#">View More</NavLink>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-6 col-xl-4'>
                            <div className='performer border-light-green'>
                                <div className='row mx-0'>
                                    <div className='col-md-4'><img src="assets/images/childrens_corner/Dyutima_Sharma.png" className='w-100' alt="Performer" /></div>
                                    <div className='col-md-8'>
                                        <h6>Dyutima Sharma</h6>
                                        <p>Chief Clinical Psychologist </p>
                                        <NavLink className='view_more_btn' to="#">View More</NavLink>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-6 col-xl-4'>
                            <div className='performer border-orange'>
                                <div className='row mx-0'>
                                    <div className='col-md-4'><img src="assets/images/childrens_corner/Swati_Sengupta.png" className='w-100' alt="Performer" /></div>
                                    <div className='col-md-8'>
                                        <h6>Swati Sengupta</h6>
                                        <p>Author</p>
                                        <NavLink className='view_more_btn' to="#">View More</NavLink>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-6 col-xl-4'>
                            <div className='performer border-sky-blue'>
                                <div className='row mx-0'>
                                    <div className='col-md-4'><img src="assets/images/childrens_corner/Mukesh_Kumar.png" className='w-100' alt="Performer" /></div>
                                    <div className='col-md-8'>
                                        <h6>Mukesh Kumar</h6>
                                        <p>Calligrapher</p>
                                        <NavLink className='view_more_btn' to="#">View More</NavLink>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-6 col-xl-4'>
                            <div className='performer'>
                                <div className='row mx-0'>
                                    <div className='col-md-4'><img src="assets/images/childrens_corner/Vasudha_Ahuja.png" className='w-100' alt="Performer" /></div>
                                    <div className='col-md-8'>
                                        <h6>Vasudha Ahuja</h6>
                                        <p>Professional Storyteller</p>
                                        <NavLink className='view_more_btn' to="#">View More</NavLink>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className='artist pb-5'>
                <div className='container'>
                    <div className='row'>
                    <div className='col-md-12'>
                            <h2 class="section-heading mt-5"> Artists</h2>
                        </div>
                        {artists && artists.map(artist => (
                            <div className='col-md-6 col-xl-4' key={artist.id}>
                                <div className={`performer border-${getRandomText()}`}>
                                    <div className='row mx-0'>
                                        <div className='col-md-4'><img src={artist.image} className='w-100' alt={artist.name} /></div>
                                        <div className='col-md-8'>
                                            <h6>{artist.name}</h6>
                                            <p>{artist.portfolio}</p>
                                            <NavLink className='view_more_btn' onClick={() => handleViewMore(artist)}>View More</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {isModalOpen && selectedArtist && (
                <div className="modal show d-block" role="dialog" aria-modal="true">

                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000" >
                            <button className="modal-close icofont-close" onClick={closeModal}></button>
                            <div className="product-view">

                                <div className="col-md-12 col-lg-12">
                                    <div className="row view-artist">
                                        <div className='col-md-12'>
                                            <h3 className="view-name">

                                            </h3>
                                        </div>
                                        <div className='col-md-12 text-center'>
                                            <img src={selectedArtist.image} alt={selectedArtist.name} className="img-fluid" />
                                            <h3 className="modal-title mt-3">{selectedArtist.name}</h3>
                                            <h4 className="info-ans">
                                                {selectedArtist.portfolio &&
                                                    selectedArtist.portfolio.split('-').map((line, index) => (
                                                        <p key={index}>{line.trim()}</p>
                                                    ))
                                                }
                                            </h4>

                                            <p className="info-ans mb-5">{selectedArtist.description}</p>
                                        </div>


                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            )}


        </>
    )
}


