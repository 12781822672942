import React from 'react';
import InternalBanner from './InternalBanner';

export default function Schedule() {
  return (
    <>
      <InternalBanner />
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <h2 class="section-heading mt-5">Schedule</h2>
          </div>
          <div className='col-md-12'>
            <div class="card schedule_tabs" >

              <div>
                <div class="nav nav-tabs mb-0" id="nav-tab" role="tablist">
                  <button class="nav-link active" id="day_1" data-bs-toggle="tab" data-bs-target="#nav-day_1" type="button" role="tab" aria-controls="nav-day_1" aria-selected="true">Day 1</button>
                  <button class="nav-link" id="day_2" data-bs-toggle="tab" data-bs-target="#nav-day_2" type="button" role="tab" aria-controls="nav-day_2" aria-selected="false">Day 2</button>
                  <button class="nav-link" id="day_3" data-bs-toggle="tab" data-bs-target="#nav-day_3" type="button" role="tab" aria-controls="nav-day_3" aria-selected="false">Day 3</button>
                  <button class="nav-link" id="day_4" data-bs-toggle="tab" data-bs-target="#nav-day_4" type="button" role="tab" aria-controls="nav-day_4" aria-selected="true">Day 4</button>
                  <button class="nav-link" id="day_5" data-bs-toggle="tab" data-bs-target="#nav-day_5" type="button" role="tab" aria-controls="nav-day_5" aria-selected="false">Day 5</button>
                  <button class="nav-link" id="day_6" data-bs-toggle="tab" data-bs-target="#nav-day_6" type="button" role="tab" aria-controls="nav-day_6" aria-selected="false">Day 6</button>
                  <button class="nav-link" id="day_7" data-bs-toggle="tab" data-bs-target="#nav-day_7" type="button" role="tab" aria-controls="nav-day_7" aria-selected="false">Day 7</button>
                  <button class="nav-link" id="day_8" data-bs-toggle="tab" data-bs-target="#nav-day_8" type="button" role="tab" aria-controls="nav-day_8" aria-selected="false">Day 8</button>
                  <button class="nav-link" id="day_9" data-bs-toggle="tab" data-bs-target="#nav-day_9" type="button" role="tab" aria-controls="nav-day_9" aria-selected="false">Day 9</button>
                </div>
              </div>
              <div class="tab-content bg-light" id="nav-tabContent">
                <div class="tab-pane fade active show" id="nav-day_1" role="tabpanel" aria-labelledby="nav-home-tab">
                  <div class="container table-responsive">
                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr className='text-left'>
                          <th colspan="4">30th November 2024, Saturday</th>
                        </tr>
                        <tr className='text-left'>
                          <th colspan="4">Workshop Stage</th>
                        </tr>

                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Conducted By</th>
                        </tr>

                      </thead>
                      <tbody>
                        <tr>

                          <td>11:00 AM – 01:00 PM</td>
                          <td>The Journey of Chhota Bheem</td>
                          <td>Sitaram Rajiv Chilakalapudi</td>
                        </tr>



                      </tbody>
                    </table>

                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr >
                          <th colspan="4">Literary Sessions</th>
                        </tr>
                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Speakers</th>
                        </tr>


                      </thead>

                      <tbody>
                        <tr>

                          <td>05:00 PM – 05:30 PM</td>
                          <td>Panel Discussion on Gujarati Novel: ‘સાહયનો ગધમ’</td>
                          <td>Padma Shri Raghuveer Chaudhary,<br /> Padma Shri Kumar Pal Desai & Dr. Bhagesh Jha</td>
                        </tr>
                        <tr>

                          <td>05:45 PM - 06:15 PM</td>
                          <td>Digital Technologies and Human Purpose</td>
                          <td>Speaker: Pankaj Setia <br />
                            Moderator: Tarun Vijh</td>

                        </tr>
                        <tr>

                          <td>06:30 PM - 07:00 PM</td>
                          <td>---</td>
                          <td>---</td>

                        </tr>


                      </tbody>
                    </table>

                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr >
                          <th colspan="4">International Stage</th>
                        </tr>
                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Speakers</th>
                        </tr>


                      </thead>
                      <tbody>
                        <tr>

                          <td>07:15 PM – 07:45 PM</td>
                          <td>Sitaram Rajiv Chilakalapudi</td>
                          <td>Guillermo Rodriguez Martin & Abhay K.</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>

                </div>
                <div class="tab-pane fade" id="nav-day_2" role="tabpanel" aria-labelledby="nav-profile-tab">
                  <div class="container table-responsive">
                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr >
                          <th colspan="4">1st December 2024, Sunday</th>
                        </tr>
                        <tr className='text-left'>
                          <th colspan="4">Workshop Stage</th>
                        </tr>

                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Conducted By</th>
                        </tr>

                      </thead>
                      <tbody>
                        <tr>

                          <td>11:00 AM – 01:00 PM</td>
                          <td>Gujarati Short Story Writing Workshop</td>
                          <td>Kirit Dudhat</td>
                        </tr>



                      </tbody>
                    </table>

                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr >
                          <th colspan="4">Literary Sessions</th>
                        </tr>
                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Speakers</th>
                        </tr>


                      </thead>

                      <tbody>
                        <tr>

                          <td>05:00 PM – 05:30 PM</td>
                          <td> Gazal Samelan - ‘કિવસમલન’</td>
                          <td>Rajesh Vyash ‘Miskin’, Bharat Bhatt, Dina Shah, Sanju Vala, Rajendr Shukal, <br />
                            Moderator: Hiten Anadpara</td>
                        </tr>
                        <tr>

                          <td>05:45 PM - 06:15 PM</td>
                          <td>Cultural Narratives in Indian Cinema</td>
                          <td> Speakers : Kulpreet Yadav, Charudutt Acharya and Utpal Borpujari<br />
                            Moderator : Kritika Mehta</td>

                        </tr>
                        <tr>

                          <td>06:30 PM - 07:00 PM</td>
                          <td>Session on Poetry</td>
                          <td>Harshad Trivedi, Kirit Dudhat</td>

                        </tr>


                      </tbody>
                    </table>

                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr >
                          <th colspan="4">International Stage</th>
                        </tr>
                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Speakers</th>
                        </tr>


                      </thead>
                      <tbody>
                        <tr>

                          <td>07:15 PM – 07:45 PM</td>
                          <td>---</td>
                          <td>---</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="tab-pane fade" id="nav-day_3" role="tabpanel" aria-labelledby="nav-contact-tab">
                  <div class="container table-responsive">
                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr className='text-left'>
                          <th colspan="4">2nd December 2024, Monday</th>
                        </tr>
                        <tr className='text-left'>
                          <th colspan="4">Workshop Stage</th>
                        </tr>

                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Conducted By</th>
                        </tr>

                      </thead>
                      <tbody>
                        <tr>

                          <td>11:00 AM – 01:00 PM</td>
                          <td>Workshop on Parenting</td>
                          <td>Prashant Agarwal</td>
                        </tr>



                      </tbody>
                    </table>

                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr >
                          <th colspan="4">Literary Sessions</th>
                        </tr>
                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Speakers</th>
                        </tr>


                      </thead>

                      <tbody>
                        <tr>

                          <td>05:00 PM – 05:30 PM</td>
                          <td>Panel Discussion Varta ni Varta on Gujarat Sahitya - વાતાની વાતા</td>
                          <td>Raghavji Madhad & Ram Mori</td>
                        </tr>
                        <tr>

                          <td>05:45 PM - 06:15 PM</td>
                          <td>Let's Talk Money </td>
                          <td> Monika Halan</td>

                        </tr>
                        <tr>

                          <td>06:30 PM - 07:00 PM</td>
                          <td>Session "Shattering stereotypes: Gender blindspots in Indian Society</td>
                          <td>Anisha Motwani and Priyadarshini Narendra</td>

                        </tr>


                      </tbody>
                    </table>

                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr >
                          <th colspan="4">International Stage</th>
                        </tr>
                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Speakers</th>
                        </tr>


                      </thead>
                      <tbody>
                        <tr>

                          <td>07:15 PM – 07:45 PM</td>
                          <td>Session "The Maharaja's Refuge: A Story of Hope During WWII" with Shania Sarup and Monica Kowaleczko-Szumowska</td>
                          <td>Shania Sarup and Monica Kowaleczko-Szumowska</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="tab-pane fade" id="nav-day_4" role="tabpanel" aria-labelledby="nav-contact-tab">
                  <div class="container table-responsive">
                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr className='text-left'>
                          <th colspan="4">3rd December 2024, Tuesday</th>
                        </tr>
                        <tr className='text-left'>
                          <th colspan="4">Workshop Stage</th>
                        </tr>

                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Conducted By</th>
                        </tr>

                      </thead>
                      <tbody>
                        <tr>

                          <td>11:00 AM – 01:00 PM</td>
                          <td>Handicraft Workshop</td>
                          <td>R R Jadav</td>
                        </tr>



                      </tbody>
                    </table>

                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr >
                          <th colspan="4">Literary Sessions</th>
                        </tr>
                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Speakers</th>
                        </tr>


                      </thead>

                      <tbody>
                        <tr>

                          <td>05:00 PM – 05:30 PM</td>
                          <td>Geet Samelan - ‘ગીત સમલન’</td>
                          <td>Vinod Joshi, Pratapsinh Dabhi, Harish Minashru,<br /> Ushaben Upadhyay, Prashan Kishor Jadav, Mukesh Joshi<br />
                            Moderator: Hardwar Goswami</td>
                        </tr>
                        <tr>

                          <td>05:45 PM - 06:15 PM</td>
                          <td> अंडमान - एक अद्भुत आकर्षण</td>
                          <td>Speaker: Kumud Verma <br />
                            Moderator: Mamta Singh</td>

                        </tr>
                        <tr>

                          <td>06:30 PM - 07:00 PM</td>
                          <td>---</td>
                          <td>---</td>

                        </tr>


                      </tbody>
                    </table>

                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr >
                          <th colspan="4">International Stage</th>
                        </tr>
                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Speakers</th>
                        </tr>


                      </thead>
                      <tbody>
                        <tr>

                          <td>07:15 PM – 07:45 PM</td>
                          <td>Hindi: The Language That Binds</td>
                          <td>Siksha Dhunputh (Mauritius based) and Bence (Hungarian)</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="tab-pane fade" id="nav-day_5" role="tabpanel" aria-labelledby="nav-contact-tab">
                  <div class="container table-responsive">
                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr className='text-left'>
                          <th colspan="4">4th December 2024, Wednesday</th>
                        </tr>
                        <tr className='text-left'>
                          <th colspan="4">Workshop Stage</th>
                        </tr>

                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Conducted By</th>
                        </tr>

                      </thead>
                      <tbody>
                        <tr>

                          <td>11:00 AM – 01:00 PM</td>
                          <td>Book Launch of Whos Next,Shaljam,The Mouse And Pencil</td>
                          <td>Eklavya Publication</td>
                        </tr>



                      </tbody>
                    </table>

                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr >
                          <th colspan="4">Literary Sessions</th>
                        </tr>
                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Speakers</th>
                        </tr>


                      </thead>

                      <tbody>
                        <tr>

                          <td>05:00 PM – 05:30 PM</td>
                          <td>Panel Discussion Hasya Gujarat - ‘હાયતરંગ’ </td>
                          <td>Ratilal Borisagar, Padma Shri Jagdish Trivedi & Padma Shri Shahbuddin Rathod</td>
                        </tr>
                        <tr>

                          <td>05:45 PM - 06:15 PM</td>
                          <td>Undercover Heroines: Reimagining the Role of Women</td>
                          <td> Savie Karnel & Kiran Manral</td>

                        </tr>
                        <tr>

                          <td>06:30 PM - 07:00 PM</td>
                          <td>'Bhasha Literatures in a Globalized World: The Way Ahead'</td>
                          <td>EV Ramakishan</td>

                        </tr>


                      </tbody>
                    </table>

                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr >
                          <th colspan="4">International Stage</th>
                        </tr>
                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Speakers</th>
                        </tr>


                      </thead>
                      <tbody>
                        <tr>

                          <td>07:15 PM – 07:45 PM</td>
                          <td>Bridging Borders, Bonding Through Books: Celebrating Indo-Sri Lankan Literary Ties</td>
                          <td>Sri Lankan Author - V. V. Pathmaseeli</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="tab-pane fade" id="nav-day_6" role="tabpanel" aria-labelledby="nav-contact-tab">
                  <div class="container table-responsive">
                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr className='text-left'>
                          <th colspan="4">5th December 2024, Thursday</th>
                        </tr>
                        <tr className='text-left'>
                          <th colspan="4">Workshop Stage</th>
                        </tr>

                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Conducted By</th>
                        </tr>

                      </thead>
                      <tbody>
                        <tr>

                          <td>11:00 AM – 01:00 PM</td>
                          <td>Reading Workshop for Underprivilege Children</td>
                          <td>Ramesh Tana</td>
                        </tr>



                      </tbody>
                    </table>

                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr >
                          <th colspan="4">Literary Sessions</th>
                        </tr>
                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Speakers</th>
                        </tr>


                      </thead>

                      <tbody>
                        <tr>

                          <td>05:00 PM – 05:30 PM</td>
                          <td>Dastan Goi - ‘કથાકથન’</td>
                          <td>Hiten Anandpara and Team</td>
                        </tr>
                        <tr>

                          <td>05:45 PM - 06:15 PM</td>
                          <td>Book Discussion on Gods Dancing Among Men</td>
                          <td>Pushpa Kurup</td>

                        </tr>
                        <tr>

                          <td>06:30 PM - 07:00 PM</td>
                          <td>Session on “Melody and Emotion: The Heart of Hindi Film Songs" </td>
                          <td>Amitabh Verma (Lyricist) & Rajiv Vijayakar (Author/Critic)</td>

                        </tr>


                      </tbody>
                    </table>

                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr >
                          <th colspan="4">International Stage</th>
                        </tr>
                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Speakers</th>
                        </tr>


                      </thead>
                      <tbody>
                        <tr>

                          <td>07:15 PM – 07:45 PM</td>
                          <td>Session on Leadership</td>
                          <td>Mette Johansson, Rajeev Peshawaria</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="tab-pane fade" id="nav-day_7" role="tabpanel" aria-labelledby="nav-contact-tab">
                  <div class="container table-responsive">
                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr className='text-left'>
                          <th colspan="4">6th December 2024, Friday</th>
                        </tr>
                        <tr className='text-left'>
                          <th colspan="4">Workshop Stage</th>
                        </tr>

                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Conducted By</th>
                        </tr>

                      </thead>
                      <tbody>
                        <tr>

                          <td>11:00 AM – 01:00 PM</td>
                          <td>---</td>
                          <td>---</td>
                        </tr>



                      </tbody>
                    </table>

                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr >
                          <th colspan="4">Literary Sessions</th>
                        </tr>
                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Speakers</th>
                        </tr>


                      </thead>

                      <tbody>
                        <tr>

                          <td>05:00 PM – 05:30 PM</td>
                          <td>---</td>
                          <td>---</td>
                        </tr>
                        <tr>

                          <td>05:45 PM - 06:15 PM</td>
                          <td>---</td>
                          <td>---</td>

                        </tr>
                        <tr>

                          <td>06:30 PM - 07:00 PM</td>
                          <td>The Power of Professional Mentorship</td>
                          <td>Dr. Vivek Mansingh</td>

                        </tr>


                      </tbody>
                    </table>

                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr >
                          <th colspan="4">International Stage</th>
                        </tr>
                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Speakers</th>
                        </tr>


                      </thead>
                      <tbody>
                        <tr>

                          <td>07:15 PM – 07:45 PM</td>
                          <td>Narratives - The Stories That Hold Women Back at Work</td>
                          <td>Mette Johansson</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="tab-pane fade" id="nav-day_8" role="tabpanel" aria-labelledby="nav-contact-tab">
                  <div class="container table-responsive">
                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr className='text-left'>
                          <th colspan="4">7th December 2024, Saturday</th>
                        </tr>
                        <tr className='text-left'>
                          <th colspan="4">Workshop Stage</th>
                        </tr>

                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Conducted By</th>
                        </tr>

                      </thead>
                      <tbody>
                        <tr>
                          <td>11:00 AM – 01:00 PM</td>
                          <td>Hindi Book Launch of Achieving Meaningful Success</td>
                          <td>Dr. Vivek Mansingh</td>
                        </tr>



                      </tbody>
                    </table>

                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr >
                          <th colspan="4">Literary Sessions</th>
                        </tr>
                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Speakers</th>
                        </tr>


                      </thead>

                      <tbody>
                        <tr>

                          <td>05:00 PM – 05:30 PM</td>
                          <td>New Face of Gujarati Cinema</td>
                          <td>Manish Saini</td>
                        </tr>
                        <tr>

                          <td>05:45 PM - 06:15 PM</td>
                          <td>Session on "Building a Personal Brand"</td>
                          <td>Saurabh Bajaj</td>

                        </tr>
                        <tr>

                          <td>06:30 PM - 07:00 PM</td>
                          <td>---</td>
                          <td>---</td>

                        </tr>


                      </tbody>
                    </table>

                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr >
                          <th colspan="4">International Stage</th>
                        </tr>
                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Speakers</th>
                        </tr>


                      </thead>
                      <tbody>
                        <tr>

                          <td>07:15 PM – 07:45 PM</td>
                          <td>"The Golden Road: How Ancient India Transformed the World"</td>
                          <td>William Dalrymple</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="tab-pane fade" id="nav-day_9" role="tabpanel" aria-labelledby="nav-contact-tab">
                  <div class="container table-responsive">
                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr className='text-left'>
                        <th colspan="4">8th December 2024, Sunday</th>
                        </tr>
                        <tr className='text-left'>
                          <th colspan="4">Workshop Stage</th>
                        </tr>

                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Conducted By</th>
                        </tr>

                      </thead>
                      <tbody>
                        <tr>

                          <td>11:00 AM – 01:00 PM</td>
                          <td>Poetry Writing Workshop: Kavya Lekhan Shivir</td>
                          <td>Dr. Raish Maniyar</td>
                        </tr>



                      </tbody>
                    </table>

                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr >
                          <th colspan="4">Literary Sessions</th>
                        </tr>
                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Speakers</th>
                        </tr>


                      </thead>

                      <tbody>
                        <tr>

                          <td>05:00 PM – 05:30 PM</td>
                          <td>---</td>
                          <td>---</td>
                        </tr>
                        <tr>

                          <td>05:45 PM - 06:15 PM</td>
                          <td>यात्रा और पुस्तक संस्कृति</td>
                          <td>Rajesh Vyas</td>

                        </tr>
                        <tr>

                          <td>06:30 PM - 07:00 PM</td>
                          <td>"Modern Hearts: Exploring Contemporary Love Stories Through the Indian Lens"</td>
                          <td>Nona Uppal</td>

                        </tr>


                      </tbody>
                    </table>

                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr >
                          <th colspan="4">International Stage</th>
                        </tr>
                        <tr>

                          <th scope="col">Time</th>
                          <th scope="col">Session Name</th>
                          <th scope="col">Speakers</th>
                        </tr>


                      </thead>
                      <tbody>
                        <tr>

                          <td>07:15 PM – 07:45 PM</td>
                          <td>---</td>
                          <td>---</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
