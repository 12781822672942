export default function Footer() {

   


    return (
        <footer className="footer-part">
            <div class="footer-bottom">
                <p class="footer-copytext">©2024 by Ahmedabad International Book Festival.
                </p>

            </div>
        </footer>
    )
}
