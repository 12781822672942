import React from 'react';
import InternalBanner from './InternalBanner';

export default function BlogNews() {
    return (
        <>
            <InternalBanner />
            <section className='section-aboutUs ex_bg pb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>

                            <h2 className='section-heading mt-5'>Article</h2>
                            <p className="mt-3">Ahmedabad International Book Festival Goes Truly International</p>
                            <p className="mt-3">In a world where connections transcend borders, Ahmedabad stands as a vibrant cultural bridge between India and Spain. Known for its heritage and artistic traditions, the city has fostered deep ties with Valladolid, Spain, as twin cities since 2019.
                                These ties reflect not just shared cultural values but also a history of meaningful exchanges, exemplified by figures like Father Carlos Gonzalvez Valles.</p>
                            <p className="mt-3">A Spanish Jesuit priest and celebrated author, Father Carlos spent nearly five decades in Gujarat, where he became a beloved figure. Mastering the Gujarati language, he made profound contributions to its literature, writing about themes of spirituality,
                                humanity, and social transformation. His works continue to be celebrated, cementing his place as a cultural bridge between the two regions.</p>
                            <p className="mt-3">The bond between India and Spain is set to grow even stronger as 2026 will be celebrated as the India-Spain Year of Culture, Tourism, and AI. This shared commitment to collaboration and cultural enrichment serves as the perfect backdrop for the Ahmedabad
                                International Book Festival 2024. Powered by National Book Trust, India, the festival is a global platform showcasing the best of art, literature, and creativity.</p>
                            <p className="mt-3">Two prominent cultural ambassadors of Spain, Monica de la Fuente and Guillermo Rodríguez Martín, bring their unique talents to the festival, exemplifying the spirit of cross-cultural exchange. Monica, a distinguished dancer, choreographer, and researcher
                                in Indian performing arts, is celebrated for her mastery of Bharatnatyam and Kathakali. As the artistic director of the Laboratory for Performing Arts at Casa de la India—Spain’s premier India Cultural Centre—she has dedicated her career to interpreting
                                Indian classical arts through a personal and innovative lens. At the festival, Monica will perform Tariqa: Mystic Travelers from Spain to India, a mesmerizing blend of dance, music, and poetry. Accompanying her on stage is Vidya Shah, an acclaimed
                                musician and scholar of Indian music. Their performance is scheduled for 8 PM on November 30 at the Cultural Stage.</p>
                            <p className="mt-3">Guillermo, a noted scholar and author, explores India’s literary traditions with a deep passion. Among his notable works is a critical biographical essay on Indian poet and linguist A.K. Ramanujan. At 7:15 PM on November 30, Guillermo will take the
                                International Stage to present In Search of Soma: From the Vedas to the Poetry of A.K. Ramanujan. His session promises to delve into the timeless connections between ancient Indian texts and modern poetic thought.</p>
                            <p className="mt-3">The Ahmedabad International Book Festival 2024 stands as a testament to National Book Trust, India’s efforts to foster global literary and cultural exchanges. With participants from around the world, the festival is a celebration of storytelling,
                                dialogue, and artistic expression.</p>
                            <p>As Monica and Guillermo take center stage, they symbolize the enduring bond between India and Spain, honoring pioneers like Father Carlos and paving the way for future collaborations.</p>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}
