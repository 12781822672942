import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToSection() {
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.replace("#", ""));
            if (element) {
                const yOffset = -120; 
                const yPosition = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: yPosition, behavior: "smooth" });
            }
        }
    }, [hash]);

    return null;
}

export default ScrollToSection;
