import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Pagination from 'react-js-pagination';
import PDFLoader from './PDFLoader';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
 /* pdfjs.GlobalWorkerOptions.workerSrc = new URL(
   'pdfjs-dist/build/pdf.worker.min.mjs',
   import.meta.url,
).toString();  */

export default function Schedule2() {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1)
    const [scale, setScale] = useState(1.0);
    const staticPDFUrl = "assets/docs/schedule_8.pdf";


    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const handlePageChange = (pageNumber) => {
        setPageNumber(pageNumber);
    }

    const goToPreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    const goToNextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    };

    const zoomIn = () => {
        setScale(prevScale => (prevScale < 2.0 ? prevScale + 0.2 : prevScale)); // Max scale of 2.0
    };

    const zoomOut = () => {
        setScale(prevScale => (prevScale > 0.5 ? prevScale - 0.2 : prevScale)); // Min scale of 0.5
    };

    const resetZoom = () => {
        setScale(1.0);
    };



    return (
        <>
            <section className='pdf-viewer'>
                <div className='container'>
                    <div className='row'>
                        <div className='d-flex justify-content-end align-items-center '>
                            <a href='../assets/docs/schedule_8.pdf' download="schedule_8.pdf" className="download_btnn btn f_increase view-all-btnn me-2"><i class="fas fa-download"></i></a>
                            <button className="btn f_increase view-all-btnn me-2" onClick={zoomIn}><i class="fas fa-search-plus"></i></button>
                            <button className="btn f_increase view-all-btnn me-2" onClick={resetZoom}><i class="fas fa-redo-alt"></i></button>
                            <button className="btn f_increase view-all-btnn me-2" onClick={zoomOut}><i class="fas fa-search-minus"></i></button>
                        </div>
                        <div className='col-md-12 text-start position-relative'>

                            <Document file={staticPDFUrl} onLoadSuccess={onDocumentLoadSuccess} loading={<PDFLoader />}>
                                <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} scale={scale} />
                            </Document>

                            {numPages && (
                                <Pagination
                                    activePage={pageNumber}
                                    itemsCountPerPage={1}
                                    totalItemsCount={numPages}
                                    pageRangeDisplayed={3}
                                    onChange={handlePageChange}
                                    itemClass="page-item d-none"
                                    linkClass="page-link d-none"
                                />
                            )}


                            <button class="carousel-control-prev d-none" type="button" onClick={goToPreviousPage}
                                disabled={pageNumber === 1}>
                                <span class="btn-6 view-all-btnn view-all-btnn-cr" aria-hidden="true">
                                    <img src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e" alt="Arrow Icon" />
                                </span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next d-none" type="button" onClick={goToNextPage}
                                disabled={pageNumber === numPages}>
                                <span class="btn-6 view-all-btnn view-all-btnn-cr" aria-hidden="true">
                                    <img src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e" alt="Arrow Icon" />
                                </span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>

                </div>

            </section>
        </>

    )
}
