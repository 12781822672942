import React, { useEffect, useState } from 'react';
import InternalBanner from './InternalBanner';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

export default function DetailSpeakers() {

    const apiKey = process.env.REACT_APP_API_KEY;
    const baseURL = process.env.REACT_APP_BASE_URL;
    const [artists, setArtists] = useState();
    const [speakers, setSpeakers] = useState();
    const [selectedArtist, setSelectedArtist] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const randomTexts = ["red", "green", "blue", "sky-blue", "yellow", "purple", "orange", "light-green"];
    const getRandomText = () => randomTexts[Math.floor(Math.random() * randomTexts.length)];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseURL}/get-all-speakers`, {
                    headers: {
                        'X-API-KEY': apiKey,
                        accept: 'application/json',
                    },
                });

                if (response.status === 200) {
                    setIsSuccess(true); // Set success to true
                    setSpeakers(response.data);
                } else {
                    setIsSuccess(false); // Set success to false
                }
                console.log('Response:', response);
                //setSpeakers(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [baseURL, apiKey]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseURL}/get-all-children-resource`, {
                    headers: {
                        'X-API-KEY': apiKey,
                        accept: 'application/json',
                    },
                });
                
                console.log('Response:', response);
                setArtists(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [baseURL, apiKey]);


    const handleViewMore = (artist) => {
        setSelectedArtist(artist);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedArtist(null);
    };



    return (
        <>
            <InternalBanner />
            {isSuccess ? (
                <section className='speaker_tab pt-5'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div class="card schedule_tabs" >

                                    <div>
                                        <div class="nav nav-tabs mb-0" id="nav-tab" role="tablist">
                                            <button class="nav-link active" id="day_1" data-bs-toggle="tab" data-bs-target="#nav-day_1" type="button" role="tab" aria-controls="nav-day_1" aria-selected="true">Speakers</button>
                                            <button class="nav-link" id="day_2" data-bs-toggle="tab" data-bs-target="#nav-day_2" type="button" role="tab" aria-controls="nav-day_2" aria-selected="false">Artists</button>

                                        </div>
                                    </div>
                                    <div class="tab-content bg-light" id="nav-tabContent">
                                        <div class="tab-pane fade active show" id="nav-day_1" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <section className='speakers_sec pb-5'>
                                                <div className='container'>
                                                    <div className='row'>

                                                        {speakers && speakers.map(speakers => (
                                                            <div className='col-md-6 col-xl-3' key={speakers.id}>
                                                                <div className={`performer border-${getRandomText()}`}>
                                                                    <div className='row'>
                                                                        <div className='col-md-12'>
                                                                            <img src={speakers.profile_image} className='w-100' alt={speakers.name} />
                                                                            <h6>{speakers.name}</h6>
                                                                            <NavLink className='view_more_btn' onClick={() => handleViewMore(speakers)}>View More</NavLink>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        ))}
                                                    </div>
                                                </div>
                                            </section>

                                        </div>
                                        <div class="tab-pane fade" id="nav-day_2" role="tabpanel" aria-labelledby="nav-profile-tab">
                                            <section className='pb-5'>
                                                <div className='container'>
                                                    <div className='row'>

                                                        {artists && artists.map(artist => (
                                                            <div className='col-md-6 col-xl-4' key={artist.id}>
                                                                <div className={`performer border-${getRandomText()}`}>
                                                                    <div className='row mx-0'>
                                                                        <div className='col-md-4'><img src={artist.profile_image} className='w-100' alt={artist.name} /></div>
                                                                        <div className='col-md-8'>
                                                                            <h6>{artist.name}</h6>
                                                                            <p>{artist.portfolio}</p>
                                                                            <NavLink className='view_more_btn' onClick={() => handleViewMore(artist)}>View More</NavLink>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </section>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            ) : (
                <section className='artist speakers_sec pb-5'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <h2 class="section-heading mt-5">Speakers</h2>
                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker1.png" className='w-100' alt="Performer" />
                                            <h4>bhagesh jha</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            {/* <div className='col-md-6 col-xl-3'>
                            <div className='performer'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <img src="assets/images/speakers/new_speaker/speaker2.png" className='w-100' alt="Performer" />
                                        <h4>savie karnel</h4>
                                        <NavLink className='view_more_btn' to="#">View More</NavLink>
                                    </div>

                                </div>
                            </div>

                        </div> */}
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker3.png" className='w-100' alt="Performer" />
                                            <h4>saurabh bajaj</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker4.png" className='w-100' alt="Performer" />
                                            <h4>ratilal borisagar</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker5.png" className='w-100' alt="Performer" />
                                            <h4>kavita kane</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker6.png" className='w-100' alt="Performer" />
                                            <h4>Rajiv vijayakar</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker7.png" className='w-100' alt="Performer" />
                                            <h4>raghuveer chaudhary</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker8.png" className='w-100' alt="Performer" />
                                            <h4>raam mori</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker9.png" className='w-100' alt="Performer" />
                                            <h4>priyadarshani narendra</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker10.png" className='w-100' alt="Performer" />
                                            <h4>pankaj setia</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker11.png" className='w-100' alt="Performer" />
                                            <h4>nora uppal</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker12.png" className='w-100' alt="Performer" />
                                            <h4>anisha motwani</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker13.png" className='w-100' alt="Performer" />
                                            <h4>monika halann</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker14.png" className='w-100' alt="Performer" />
                                            <h4>monica kowaleczko</h4>
                                            {/*  <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker15.png" className='w-100' alt="Performer" />
                                            <h4>matte johansson</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker16.png" className='w-100' alt="Performer" />
                                            <h4>Kumarpal Desai</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker17.png" className='w-100' alt="Performer" />
                                            <h4>kulpreet yadav</h4>
                                            {/*  <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            {/* <div className='col-md-6 col-xl-3'>
                            <div className='performer'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <img src="assets/images/speakers/new_speaker/speaker18.png" className='w-100' alt="Performer" />
                                        <h4>kiran manral</h4>
                                        <NavLink className='view_more_btn' to="#">View More</NavLink>
                                    </div>

                                </div>
                            </div>

                        </div> */}
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker19.png" className='w-100' alt="Performer" />
                                            <h4>kaajal oza vaidya</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker20.png" className='w-100' alt="Performer" />
                                            <h4>jay vasavada</h4>
                                            {/*  <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker21.png" className='w-100' alt="Performer" />
                                            <h4>jagdish trivedi</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker22.png" className='w-100' alt="Performer" />
                                            <h4>ev ramakrishnan</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker23.png" className='w-100' alt="Performer" />
                                            <h4>charudutt acharya</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker24.png" className='w-100' alt="Performer" />
                                            <h4>william dalrymple</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker25.png" className='w-100' alt="Performer" />
                                            <h4>Vivek mansingh</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker26.png" className='w-100' alt="Performer" />
                                            <h4>utpal borpujari</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker27.png" className='w-100' alt="Performer" />
                                            <h4>tushar shukla</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker28.png" className='w-100' alt="Performer" />
                                            <h4>subhash ghai</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker29.png" className='w-100' alt="Performer" />
                                            <h4>shahbuddin rathod</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker30.png" className='w-100' alt="Performer" />
                                            <h4>amitabh verma</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker31.png" className='w-100' alt="Performer" />
                                            <h4>ManiLal H Patel</h4>
                                            {/*  <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <div className='performer'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src="assets/images/speakers/new_speaker/speaker32.png" className='w-100' alt="Performer" />
                                            <h4>Manish Saini</h4>
                                            {/* <NavLink className='view_more_btn' to="#">View More</NavLink> */}
                                        </div>

                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </section>
            )}








            {isModalOpen && selectedArtist && (
                <div className="modal show d-block" role="dialog" aria-modal="true">

                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000" >
                            <button className="modal-close icofont-close" onClick={closeModal}></button>
                            <div className="product-view">

                                <div className="col-md-12 col-lg-12">
                                    <div className="row view-artist">
                                        <div className='col-md-12'>
                                            <h3 className="view-name">

                                            </h3>
                                        </div>
                                        <div className='col-md-12 text-center'>
                                            <img src={selectedArtist.profile_image} alt={selectedArtist.name} className="img-fluid" />
                                            <h3 className="modal-title mt-3">{selectedArtist.name}</h3>
                                            <h4 className="info-ans">
                                                {selectedArtist.portfolio &&
                                                    selectedArtist.portfolio.split('-').map((line, index) => (
                                                        <p key={index}>{line.trim()}</p>
                                                    ))
                                                }
                                            </h4>

                                            <p className="info-ans mb-5">{selectedArtist.description}</p>
                                        </div>


                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    )
}
