import React from 'react'

export default function InternalBanner(props) {
  return (
    <section className="single-banner internal-banner">
      <img src='assets/images/elements/internal_banner.jpg' className='w-100 desktop-view' alt="Internal Banner" />
      <img src='assets/images/elements/mobile_banner.jpg' className='w-100 mobile-view' alt="Internal Banner" />
    </section>
  )
}
