import React from 'react'

const Banner = () => {
    return (
        <div id="bannerSec" className="carousel slide bannerSec" data-bs-ride="carousel" data-bs-interval="4000">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src="assets/images/banner/banner1.jpg" className=" w-100 desktop-view " alt="banner" />
                    <img src="assets/images/banner/mobilebanner1.jpg" className="mobile-view  w-100" alt="banner" />
                </div> 
                {/* <div className="carousel-item active">
                    <img src="assets/images/banner/banner5.png" className=" w-100" alt="banner" />
                </div> */}
                 <div className="carousel-item ">
                    <img src="assets/images/banner/banner3.jpg" className=" w-100 desktop-view " alt="banner" />
                    <img src="assets/images/banner/mobilebanner2.jpg" className=" w-100 mobile-view" alt="banner" />
                </div> 
            </div>
            <button className="carousel-control-prev bannerbtn desktop-view" type="button" data-bs-target="#bannerSec" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            </button>
            <button className="carousel-control-next bannerbtn desktop-view" type="button" data-bs-target="#bannerSec" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
            </button>

        </div>
    )
}

export default Banner