import React from 'react'
import InternalBanner from './InternalBanner';


export default function Media() {
    return (
        <>

            <InternalBanner title="Media" />

            <section className='media_section my-5'>
                <div className='container'>
                    <div className='row'>

                        <div className='col-md-5'>
                            <p>
                                The Ahmedabad International Book Festival 2024, in collaboration with NBT, celebrates literature, creativity, and culture. It brings together readers, authors, and publishers to explore diverse works and engage in enriching discussions. The event highlights:
                            </p>
                            <ul>
                                <li>The role of books in shaping society.</li>
                                <li>Promotion of regional, Indian, and global literature.</li>
                                <li>Encouraging reading habits in children and young adults.</li>
                                <li>Supporting authors by enhancing their visibility.</li>
                            </ul>
                            <p>
                                With book launches, meet-the-author sessions, panels, workshops, and cultural events, the festival offers something for everyone. Join us in celebrating the joy of reading at this vibrant literary gathering!
                            </p>
                        </div>
                        <div className='col-md-7'>
                            <div id="galleryBanner" className="carousel slide bannerSec" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src="assets/images/media/media_banner_1.jpeg" className=" w-100" alt="banner" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="assets/images/media/media_banner_2.jpeg" className=" w-100" alt="banner" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="assets/images/media/media_banner_3.jpeg" className=" w-100" alt="banner" />
                                    </div>
                                </div>
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#galleryBanner" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#galleryBanner" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#galleryBanner" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='youtube_live py-5'>
                <div className='container'>
                    <div className='row'>
                        {/* <div className='col-md-12'>
                            <h2 className='section-heading mt-5'>Youtube Live</h2>
                        </div> */}
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-md-8'>
                            <iframe width="100%" height="405" src="https://www.youtube.com/embed/IcNUV0s5024?autoplay=1&mute=1" title="AHMEDABAD INTERNATIONAL BOOK FESTIVAL 2024" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </section>

            <section className='gallery_section py-5 d-none'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2 className='section-heading'>Gallery</h2>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <img src="assets/images/banner/banner.jpg" className='w-100' alt="Gallery" />
                        </div>
                        <div className='col-md-4'>
                            <img src="assets/images/banner/banner.jpg" className='w-100' alt="Gallery" />
                        </div>
                        <div className='col-md-4'>
                            <img src="assets/images/banner/banner.jpg" className='w-100' alt="Gallery" />
                        </div>
                        <div className='col-md-4'>
                            <img src="assets/images/banner/banner.jpg" className='w-100' alt="Gallery" />
                        </div>
                        <div className='col-md-4'>
                            <img src="assets/images/banner/banner.jpg" className='w-100' alt="Gallery" />
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}
