import React from 'react'

export default function PDFLoader() {
  return (
    <>
      <div className="container">
        <div className='row pdf_loader skeleton_section'>
          <div className='col-md-4'>
            <div className="card">
              <div className="card-img skeleton">

              </div>
              <div className="card-body">
                <h2 className="card-title skeleton">

                </h2>
                <p className="card-intro skeleton">

                </p>
              </div>
            </div>
          </div>
          
        
          
        </div>

      </div>
    </>
  )
}
